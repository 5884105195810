import {
    AdvertisementListingFilterPaginationDto,
} from 'modules/api/generated';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';

export const mapIAdvertisementListingFilterPaginationToAdvertisementListingFilterPaginationDto = (
    pagination: IAdvertisementListingFilterPagination,
): AdvertisementListingFilterPaginationDto => ({
    limit: pagination.limit,
    skip: pagination.skip,
});
