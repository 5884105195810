import { AdvertisementsListResultDevProjectDto } from 'modules/api/generated';
import { mapPriceInfoDtoToIPriceInfo } from 'modules/api/clients/advertisement/common/helpers/mappers/price/mapPriceInfoDtoToIPriceInfo';
import { mapFlagsDtoToIFlags } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/devProject/flags/mapFlagsDtoToIFlags';
import { MapMediaDtoToIMedia } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/media/mapMediaDtoToIMedia';
import { mapParametersDtoToIParameter } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/common/parameters/mapParametersDtoToIParameter';
import { mapAdvertiserDtoToIAdvertiser } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/common/advertiser/mapAdvertiserDtoToIAdvertiser';
import { IAdvertisementsListResultDevProject } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/devProject/IAdvertisementsListResultDevProject';
import { mapAdvertisementsInfoDtoToIAdvertisementsInfo } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/devProject/advertisementsInfo/mapAdvertisementsInfoDtoToIAdvertisementsInfo';
import {
    mapAdvertisementListResultLocationToILocation
} from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/location/mapAdvertisementListResultLocationToILocation';

export const mapAdvertisementsListResultDevProjectDtoToIAdvertisementsListResultDevProject =
    (
        dto: AdvertisementsListResultDevProjectDto
    ): IAdvertisementsListResultDevProject => ({
        id: dto.id,
        title: dto.title,
        sefName: dto.sefName,
        externalId: dto.externalId,
        description: dto.description,
        createdAt: dto?.createdAt || null,
        updatedAt: dto?.updatedAt || null,
        location: mapAdvertisementListResultLocationToILocation(dto.location),
        ...(!!dto.photoUrl && { photoUrl: dto.photoUrl }),

        media: MapMediaDtoToIMedia(dto.media),
        flags: mapFlagsDtoToIFlags(dto.flags),
        price: mapPriceInfoDtoToIPriceInfo(dto.price),

        parameters: mapParametersDtoToIParameter(dto.parameters),
        advertiser: mapAdvertiserDtoToIAdvertiser(dto.advertiser),
        advertisementsInfo: dto.advertisementsInfo.map(
            mapAdvertisementsInfoDtoToIAdvertisementsInfo
        ),
    });
