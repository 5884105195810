import { create } from 'zustand';

type ITotalCountStore = {
    totalCount: number;
    setTotalCount: (totalCount: number) => void;
};

export const useTotalCountStore = create<ITotalCountStore>((set) => ({
    totalCount: 0,

    setTotalCount: (totalCount) => set(() => ({ totalCount })),
}));