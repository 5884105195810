import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import ClockIcon from 'modules/theme/components/icons/basic/Clock.svg';
import MappinIcon from 'modules/theme/components/icons/basic/MappinOutline.svg';

interface IOptionContent {
    title: string;
    subTitle?: string;
    isPrevSearched?: boolean;
}

export const OptionContent: FC<IOptionContent> = ({
    title,
    subTitle,
    isPrevSearched,
}) => {

    return <>
        <Box
            p={1}
            display='flex'
            borderRadius='12px'
            alignItems='center'
            justifyContent='center'
            bgcolor={(theme) => theme.palette.backgroundTertiary.main}
        >
            <Svg
                icon
                width={24}
                component={isPrevSearched ? ClockIcon : MappinIcon}
            />
        </Box>

        <Box
            display='flex'
            overflow='hidden'
            flexDirection='column'
        >
            <Text
                noWrap
                variant='body2'
            >
                {title}
            </Text>

            {subTitle &&
                <Text
                    noWrap
                    variant='label2'
                    color={(palette) => palette.labelSecondary}
                >
                    {subTitle}
                </Text>
            }
        </Box>
    </>;
};