import { useMemo } from 'react';
import { IGridChild } from 'components/advertisementsFilter/modules/categories/interfaces/IGridChild';
import { IGridCategory } from 'components/advertisementsFilter/modules/categories/interfaces/IGridCategory';
import { IGridMainCategory } from 'components/advertisementsFilter/modules/categories/interfaces/IGridMainCategory';
import { ICategories } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import { categoryIconRecord } from 'components/advertisementsFilter/modules/categories/records/categoryIconRecord';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import {
    apiCategoryToECategoryNameRecord
} from 'components/advertisementsFilter/modules/categories/records/apiCategoryToECategoryNameRecord';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

interface IReturn {
    categoriesGrid: IGridCategory[];
}

export const useCategoriesGrid = (
    categoryOptions: ICategories,
    selectedCategoryIds: number[]
): IReturn => {
    const mainCategories: IGridMainCategory[] = useMemo(() => {
        return categoryOptions.main
            .map((category) => ({
                id: category.id,
                eName: category.name as EMainCategory,
                name: apiCategoryToECategoryNameRecord[category.name],
                isRollable: ![
                    EMainCategory.SECONDARY_HOUSES,
                    EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES,
                ].includes(category.name as EMainCategory),
            }));
    }, [categoryOptions.main]);

    const categoriesGrid: IGridCategory[] = useMemo(() => {
        return mainCategories
            .map((mainCategory) => {
                const children: IGridChild[] = [];

                categoryOptions.sub.forEach((sub) => {
                    const name = !!apiCategoryToECategoryNameRecord[sub.name];
                    if (sub.parentId === mainCategory.id && name) {
                        children.push({
                            id: sub.id,
                            wordBreak: [
                                ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
                                ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION,
                            ].includes(sub.name as ESubCategory),
                            parentEName: mainCategory.eName,
                            icon: categoryIconRecord[sub.name],
                            name: apiCategoryToECategoryNameRecord[sub.name],
                            isSelected: selectedCategoryIds.includes(sub.id),
                        });
                    }
                });

                if (
                    !(
                        selectedCategoryIds.includes(mainCategory.id) ||
                        children.some((child) =>
                            selectedCategoryIds.includes(child.id)
                        )
                    )
                ) {
                    return;
                }

                return {
                    id: mainCategory.id,
                    name: mainCategory.name,
                    eName: mainCategory.eName,
                    isRollable: mainCategory.isRollable,
                    children,
                };
            })
            ?.filter((category) => !!category);
    }, [mainCategories, categoryOptions, selectedCategoryIds]);

    return {
        categoriesGrid,
    };
};
