import { create } from 'zustand';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

type TransactionStore = {
    selectedTransaction: ITransaction;
    transactionOptions: ITransaction[];
    transactionsForFilter: ITransaction[];
    setSelectedTransaction: (transactionId: number) => void;
    setTransactionOptions: (options: ITransaction[]) => void;
};

export const useTransactionStore = create<TransactionStore>((set) => ({
    selectedTransaction: null,
    transactionOptions: [],
    transactionsForFilter: [],
    setTransactionOptions: (options: ITransaction[]) => set(() => {
        const transactionOptions = options.filter((value) =>
            value.name === ETransaction.SALE
            || value.name === ETransaction.HIREOUT
            || value.name === ETransaction.PURCHASE
        );

        return { transactionOptions };
    }),
    setSelectedTransaction: (transactionId: number) => set((state) => {
        if (!transactionId) {
            return { selectedTransaction: null };
        }

        const transactionOption = state.transactionOptions.length
            && state.transactionOptions.find((option) => option.id === transactionId);

        return { selectedTransaction: transactionOption };
    }),
}));