import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Input } from 'modules/theme/components/fields/input/Input';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import { Popover } from 'components/advertisementsFilter/modules/common/components/popover/Popover';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { Autocomplete } from 'components/advertisementsFilter/modules/extended/components/location/partials/autocomplete/Autocomplete';
import { usePreviouslySearchedLocations } from 'components/advertisementsFilter/modules/location/hooks/usePreviouslySearchedLocations';
import { usePopover } from 'components/advertisementsFilter/modules/common/hooks/popover/usePopover';
import PinIcon from 'modules/theme/components/icons/basic/MappinOutline.svg';
import {
    SelectedLocations
} from 'components/advertisementsFilter/modules/location/components/common/selectedLocations/SelectedLocations';
import {
    PreviouslySearched
} from 'components/advertisementsFilter/modules/extended/components/location/partials/previouslySearched/PreviouslySearched';
import {
    SelectLocationsHint
} from 'components/advertisementsFilter/modules/location/components/common/completeBox/partials/selectHint/SelectLocationsHint';

export const Location: FC = () => {
    const { options: previouslySearchedOptions } =
        usePreviouslySearchedLocations();

    const locationQuery = useLocationStore((store) => store.locationQuery);
    const onSelectLocation = useLocationStore((store) => store.onSelectLocation);
    const changeLocationQuery = useLocationStore((store) => store.changeLocationQuery);
    const autocompleteOptions = useLocationStore((store) => store.autocompleteOptions);
    const setSelectedLocations = useLocationStore((store) => store.setSelectedLocations);
    const selectedLocationNames = useLocationStore((store) => store.selectedLocationNames);
    const locationOptionsInitial = useLocationStore((store) => store.locationOptionsInitial);

    const showSuggestions = locationQuery && locationQuery.length >= 2 && autocompleteOptions.length;
    const showLastSearched = !!previouslySearchedOptions.length && (!locationQuery || locationQuery.length === 1);
    const showLocationOptions = !previouslySearchedOptions.length && (!locationQuery || locationQuery.length === 1);
    const popoverNeedToBeHidden = locationQuery.length &&
        locationQuery.length >= 2 &&
        !autocompleteOptions.length &&
        !selectedLocationNames.length;
    const areSelectedLocations = !!selectedLocationNames.length;

    const title = showSuggestions
        ? 'Vyberte lokalitu:'
        : showLastSearched
            ? 'Naposledy ste hľadali:'
            : showLocationOptions
                ? 'Zadajte mesto, obec, okres ...'
                : '';

    const options = showSuggestions
        ? autocompleteOptions
        : showLastSearched
            ? []
            : showLocationOptions
                ? locationOptionsInitial
                : [];

    const {
        anchorEl: anchorElLocation,
        onOpen: onOpenLocation,
        isOpen: isOpenLocation,
        onClose: onCloseLocation
    } = usePopover();

    const suggestionsComponent = (
        <Autocomplete
            title={title}
            maxHeight={250}
            options={options}
            onClick={(location) => {
                onSelectLocation(location);
                onCloseLocation();
            }}
        />
    );

    const selectLocationsHint = (
        <Box px={1}>
            <SelectLocationsHint/>
        </Box>
    );

    const lastSearchedComponent = (
        <PreviouslySearched
            title='Naposledy ste hľadali:'
            options={previouslySearchedOptions}
            onClick={(values) => {
                setSelectedLocations(values);
                onCloseLocation();
            }}
        />
    );

    const component = showLastSearched
        ? lastSearchedComponent
        : showSuggestions
            ? suggestionsComponent
            : selectLocationsHint;

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Lokalita'/>
        </Box>

        <Box
            id='filter.locationInputExtended'
            onClick={(event) => onOpenLocation(event.currentTarget)}
            sx={{
                '& .MuiInputBase-root': { height: 56 },
                '& .MuiFormControl-root .MuiOutlinedInput-root': {
                    height: 56,
                }
            }}
        >
            <Input
                textEllipsis
                autoComplete='off'
                value={isOpenLocation
                    ? locationQuery
                    : selectedLocationNames
                }
                endIconStopPropagation
                startIcon={() =>
                    <Svg icon component={PinIcon} width={24}/>
                }
                placeholder='Mesto, obec ...'
                onChange={changeLocationQuery}
            />
        </Box>

        <Popover
            width={anchorElLocation?.clientWidth}
            isOpen={isOpenLocation && !popoverNeedToBeHidden}
            borderRadius='8px'
            onClose={() => {
                onCloseLocation();
            }}
            anchorEl={anchorElLocation}
            offsetOptions={{
                x: 0,
                y: 8,
            }}
        >
            <Box
                minWidth={300}
                maxHeight={608}
                border={(theme) => `1px solid ${theme.palette.backgroundPrimary.main}`}
                sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {areSelectedLocations &&
                    <Box pt={2} px={1} pb={1}>
                        <SelectedLocations/>
                    </Box>
                }

                {component}
            </Box>
        </Popover>
    </ContentItemWrapper>;
};