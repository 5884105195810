import { FC } from 'react';
import { Box } from '@mui/material';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import {
    Option
} from 'components/advertisementsFilter/modules/location/components/common/selectedLocations/partials/option/Option';
import { useLocationInputStore } from 'components/advertisementsFilter/modules/location/store/useLocationInputStore';

export const SelectedLocations: FC = () => {
    const selectedLocations = useLocationStore((store) => store.selectedLocations);
    const onDeselectLocation = useLocationStore((store) => store.onDeselectLocation);
    const locationInputRef = useLocationInputStore((store) => store.locationInputRef);

    return <Box
        gap={1}
        display='flex'
        flexWrap='wrap'
        alignItems='center'
    >
        {selectedLocations.map((location) =>
            <Option
                key={location.id}
                location={location}
                onClick={() => {
                    onDeselectLocation(location.id);

                    if (locationInputRef && locationInputRef.current) {
                        locationInputRef.current.focus();
                    }
                }}
            />
        )}
    </Box>;
};