import { FC } from 'react';
import {
    MobileFilterWrapper
} from 'components/advertisementsFilter/components/mobile/listing/partials/wrapper/FilterWrapper';
import {
    MultiInput
} from 'components/advertisementsFilter/components/common/multiInput/MultiInput';
import { ETransactionName } from 'components/advertisementsFilter/modules/transaction/enums/ETransactionName';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';

interface IMobileFilterHeader {
    toggleFilter: (flag: boolean) => void;
}

export const MobileFilterHeader: FC<IMobileFilterHeader> = ({
    toggleFilter,
}) => {
    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);
    const selectedLocationNames = useLocationStore((store) => store.selectedLocationNames);
    const selectedCategoryNames = useCategoriesStore((store) => store.selectedCategoryNames);

    return <MobileFilterWrapper>
        <MultiInput
            isWithBackground
            isWithSearchButton
            isWithExtendedFilterButton
            location={selectedLocationNames}
            category={selectedCategoryNames}
            onSearchButtonClick={() => toggleFilter(true)}
            onExtendedButtonClick={() => toggleFilter(true)}
            transaction={selectedTransaction && ETransactionName[selectedTransaction.name]}
        />
    </MobileFilterWrapper>;
};