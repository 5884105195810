import { FC } from 'react';
import { Box } from '@mui/material';
import { CheckboxWithLabel } from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';

interface IAdvertisementsItem {
    title: string;
    isChecked: boolean;
    onChange: (isChecked: boolean) => void;
}

export const AdvertisementsItem: FC<IAdvertisementsItem> = ({
    title,
    onChange,
    isChecked,
}) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            <CheckboxWithLabel
                label={title}
                onClick={onChange}
                isChecked={isChecked}
            />
        </Box>
    );
};
