import { FC } from 'react';
import { Box } from '@mui/material';
import {
    Prices
} from 'components/advertisementsFilter/modules/extended/components/prices/Prices';
import {
    Area
} from 'components/advertisementsFilter/modules/extended/components/area/Area';
import {
    Fulltext
} from 'components/advertisementsFilter/modules/extended/components/fulltext/Fulltext';
import {
    Location
} from 'components/advertisementsFilter/modules/extended/components/location/Location';
import {
    Categories
} from 'components/advertisementsFilter/modules/extended/components/categories/Categories';
import {
    Transaction
} from 'components/advertisementsFilter/modules/extended/components/transaction/Transaction';
import {
    Advertisements
} from 'components/advertisementsFilter/modules/extended/components/advertisements/Advertisements';
import {
    Divider
} from 'components/advertisementsFilter/modules/extended/components/divider/Divider';
import {
    MarginBox
} from 'components/advertisementsFilter/modules/extended/components/common/marginBox/MarginBox';

export const DialogContent: FC = () => {

    return <Box
        width='100%'
        height='100%'
        display='flex'
        flexDirection='column'
        paddingX={{xs: 2, md: 3}}
        sx={(theme) => (
            {
                '& .MuiSvgIcon-root': {
                    color: theme.palette.labelSecondary.main
                }
            }
        )}
    >
        <Location/>

        <MarginBox>
            <Divider/>
        </MarginBox>

        <Transaction/>

        <MarginBox>
            <Divider/>
        </MarginBox>

        <Categories/>

        <MarginBox>
            <Divider/>
        </MarginBox>

        <Prices/>

        <MarginBox>
            <Divider/>
        </MarginBox>

        <Area/>

        <MarginBox>
            <Divider/>
        </MarginBox>

        <Advertisements/>

        <MarginBox>
            <Divider/>
        </MarginBox>

        <Box pb={{xs: 10, md: 0}}>
            <Fulltext/>
        </Box>
    </Box>;
};
