import { AdvertisementBreadcrumbItemLocationDto } from 'modules/api/generated';
import {
    IBreadcrumbLocation
} from 'modules/api/clients/advertisement/common/interfaces/breadcrumb/partials/part/partials/location/IBreadcrumbLocation';

export const mapAdvertisementBreadcrumbItemLocationDtoToIBreacrumbLocation = (
    advertisementBreadcrumbItemLocationDto: AdvertisementBreadcrumbItemLocationDto
): IBreadcrumbLocation => {
    return {
        id: advertisementBreadcrumbItemLocationDto.id,
        name: advertisementBreadcrumbItemLocationDto.name,
        sefName: advertisementBreadcrumbItemLocationDto.sefName,
        type: advertisementBreadcrumbItemLocationDto.type,
    };
};