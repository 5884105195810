import { AdvertisementListResultMediaDto } from 'modules/api/generated';
import { IMedia } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/common/media/IMedia';

export const MapMediaDtoToIMedia = ({
    videoUrls,
}: AdvertisementListResultMediaDto): IMedia => {
    return {
        videoUrls,
    };
};
