import { AdvertisementListingPriceInfoDto} from 'modules/api/generated';
import { IPriceInfo } from 'modules/api/clients/advertisement/common/interfaces/price/IPriceInfo';
import {
    mapPrimaryAndSecondaryPrices
} from 'modules/api/clients/advertisement/common/helpers/mappers/price/primaryAndSecondary/mapPrimaryAndSecondaryPrices';

export const mapPriceInfoDtoToIPriceInfo = (
    dto: AdvertisementListingPriceInfoDto,
): IPriceInfo => {
    const { pricePrimary, priceSecondary, priceOption } = dto;

    const {
        price,
        priceNum,
        unitPrice,
        priceValue,
        hasPriceOption,
    } = mapPrimaryAndSecondaryPrices(
        pricePrimary,
        priceSecondary,
        priceOption,
    );

    return {
        hasPriceOption,
        ...(!!price && { price }),
        ...(!!unitPrice && { unitPrice }),
        ...(!!priceValue && { priceValue }),
        ...(priceNum !== undefined && { priceNum }),
    };
};
