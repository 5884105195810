import { AdvertisementBreadcrumbItemDto } from 'modules/api/generated';
import { IBreadcrumb } from 'modules/api/clients/advertisement/common/interfaces/breadcrumb/partials/part/IBreadcrumb';
import { mapAdvertisementBreadcrumbItemLocationDtoToIBreacrumbLocation } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/breadcrumb/location/mapAdvertisementBreadcrumbItemLocationDtoToIBreadcrumbLocation';
import { mapAdvertisementBreadcrumbItemCategoryDtoToIBreadcrumbCommonParameter } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/breadcrumb/category/mapAdvertisementBreadcrumbItemCategoryDtoToIBreadcrumbCommonParameter';
import { mapAdvertisementBreadcrumbItemTransactionDtoToIBreadcrumbCommonParameter } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/breadcrumb/transaction/mapAdvertisementBreadcrumbItemTransactionDtoToIBreadcrumbCommonParameter';

export const mapBreadcrumbDtoToIBreadcrumb = (parts: AdvertisementBreadcrumbItemDto[]): IBreadcrumb[] => {
    return parts.map((part) => {
        return {
            ...(!!part.location && ({ location: mapAdvertisementBreadcrumbItemLocationDtoToIBreacrumbLocation(part?.location) })),
            ...(!!part.category && ({ category: mapAdvertisementBreadcrumbItemCategoryDtoToIBreadcrumbCommonParameter(part?.category) })),
            ...(!!part.transaction && ({ transaction: mapAdvertisementBreadcrumbItemTransactionDtoToIBreadcrumbCommonParameter(part?.transaction) }))
        };
    });
};