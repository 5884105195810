import { FC } from 'react';
import { Box } from '@mui/material';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';
import {
    OptionContent
} from 'components/advertisementsFilter/modules/location/components/common/autocompletes/common/optionContent/OptionContent';

interface IOption {
    onClick: () => void;
    withXPadding?: boolean;
    locationOptions: IAutocompleteOption[];
}

export const Option: FC<IOption> = ({
    onClick,
    withXPadding,
    locationOptions,
}) => {
    const isOneLocation = locationOptions.length === 1;
    const firstLocation = locationOptions[0];
    const isSubNameVisible = isOneLocation
        && firstLocation
        && firstLocation.subName;

    return <Box
        gap={2}
        paddingY={1}
        display='flex'
        onClick={onClick}
        alignItems='center'
        paddingX={{ xs: withXPadding ? 4 : 0 }}
        sx={(theme) => ({
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.backgroundSecondary.main
            }
        })}
    >
        <OptionContent
            title={
                locationOptions.map((option) => option.name).join(', ')
            }
            isPrevSearched
            subTitle={isSubNameVisible && firstLocation.subName}
        />
    </Box>;
};