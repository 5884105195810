import { FC } from 'react';
import { Box } from '@mui/material';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import {
    AreaInputs
} from 'components/advertisementsFilter/modules/extended/components/area/partials/areaInputs/AreaInputs';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const Area: FC = () => {
    const areas = useExtendedStore((store) => store.area);
    const setAreaTo = useExtendedStore((store) => store.setAreaTo);
    const setAreaFrom = useExtendedStore((store) => store.setAreaFrom);

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Výmera'/>
        </Box>

        <Box
            mt={{ md: 1 }}
            sx={{
                '& .MuiInputAdornment-root': {
                    pointerEvents: 'none',
                }
            }}
        >
            <AreaInputs
                areaToMain={areas.to}
                areaFromMain={areas.from}
                onChangeAreaToMain={setAreaTo}
                onChangeAreaFromMain={setAreaFrom}
            />
        </Box>
    </ContentItemWrapper>;
};