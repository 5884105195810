import { FC } from 'react';
import { Box } from '@mui/material';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import {
    PriceInputs
} from 'components/advertisementsFilter/modules/extended/components/prices/partials/priceInputs/PriceInputs';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const Prices: FC = () => {
    const prices = useExtendedStore((store) => store.prices);
    const setPriceTo = useExtendedStore((store) => store.setPriceTo);
    const setPriceFrom = useExtendedStore((store) => store.setPriceFrom);

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Cena'/>
        </Box>

        <Box
            mt={{ md: 1 }}
            sx={{
                '& .MuiInputAdornment-root': {
                    pointerEvents: 'none',
                }
            }}
        >
            <PriceInputs
                priceToMain={prices.to}
                priceFromMain={prices.from}
                onChangePriceToMain={setPriceTo}
                onChangePriceFromMain={setPriceFrom}
            />
        </Box>
    </ContentItemWrapper>;
};
