import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';
import MultilocationSvg from 'components/advertisementsFilter/modules/location/components/common/completeBox/partials/selectHint/assets/multilocation.svg';

export const SelectLocationsHint: FC = () => {

    return <Box display='flex' alignItems='center' gap={2} py={1.5}>
        <Svg component={MultilocationSvg} width={64}/>

        <Text variant='label1' color={(palette) => palette.labelSecondary}>
            Zadajte viac lokalít a zobrazte si aktuálnu ponuku naraz.
        </Text>
    </Box>;
};