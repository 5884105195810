import { FC } from 'react';
import { Skeleton, Box } from '@mui/material';

interface IFilterLoader {
    isMobile?: boolean;
}

export const FilterLoader: FC<IFilterLoader> = ({ isMobile }) => {

    return (
        <Box
            width='100%'
            display='flex'
            maxWidth={720}
            marginX='auto'
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
        >
            <Skeleton sx={{
                height: 64,
                width: '100%',
                maxWidth: 720,
                borderRadius: '99px',
                marginBottom: !isMobile && 2,
                '&.MuiSkeleton-text': {
                    transform: 'none'
                }
            }}/>
            {!isMobile &&
                <Box display='flex' width='100%' justifyContent='flex-end'>
                    <Skeleton
                        width={140}
                        height={40}
                        sx={{
                            '&.MuiSkeleton-text': {
                                transform: 'none'
                            }
                        }}
                    />
                </Box>
            }
        </Box>
    );
};