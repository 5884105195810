import { FC } from 'react';
import { Grid2, useMediaQuery, Theme } from '@mui/material';
import {
    ICategory
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import { categoryIconRecord } from 'components/advertisementsFilter/modules/categories/records/categoryIconRecord';
import {
    apiCategoryToECategoryNameRecord
} from 'components/advertisementsFilter/modules/categories/records/apiCategoryToECategoryNameRecord';
import {
    boxWidthConfig,
    boxHeightConfig,
} from 'components/advertisementsFilter/modules/categories/constants/config/boxWidthAndHeight/boxWidthAndHeightConfig';
import {
    CategoryRadioCard
} from 'components/advertisementsFilter/modules/categories/components/common/completeBox/partials/mainCategories/partials/radioCard/CategoryRadioCard';

interface IMainCategories {
    options: ICategory[];
    isExtendedFilter?: boolean;
    selectedCategoryIds: number[];
    onChangeCategory: (category: number) => void;
}

export const MainCategories: FC<IMainCategories> = ({
    options,
    isExtendedFilter,
    onChangeCategory,
    selectedCategoryIds,
}) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );
    const isSmOrDown = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const cardWidth = isExtendedFilter
        ? boxWidthConfig.category.extendedFilter
        : isSmOrDown
            ? '100%'
            : boxWidthConfig.category.default;

    const cardHeight = isExtendedFilter
        ? boxHeightConfig.category.main.extended
        : isMobile
            ? boxHeightConfig.category.main.mobile
            : boxHeightConfig.category.main.desktop;

    return <Grid2
        container
        spacing={1}
        alignItems='center'
    >
        {options.map((categoryOption) =>
            <Grid2 key={categoryOption.id}>
                <CategoryRadioCard
                    width={cardWidth}
                    height={cardHeight}
                    icon={categoryIconRecord[categoryOption.name]}
                    onClick={() => onChangeCategory(categoryOption.id)}
                    isChecked={selectedCategoryIds.includes(categoryOption.id)}
                    label={apiCategoryToECategoryNameRecord[categoryOption.name]}
                />
            </Grid2>
        )}
    </Grid2>;
};