import { create } from 'zustand';

type ExtendedStore = {
    fulltext: string;
    isActive: boolean;
    isOnlyWithVideo: boolean;
    isOnlyNewBuilding: boolean;
    prices: { from: number, to: number };
    area: { from: number, to: number };
    setPriceTo: (priceTo: number) => void;
    setPriceFrom: (priceFrom: number) => void;
    setAreaTo: (areaTo: number) => void;
    setAreaFrom: (areaFrom: number) => void;
    setFulltext: (fulltext: string) => void;
    setIsOnlyWithVideo: (flag: boolean) => void;
    setIsOnlyNewBuilding: (flag: boolean) => void;
    setIsActive: (isActive: boolean) => void;
};

export const useExtendedStore = create<ExtendedStore>((set) => ({
    fulltext: '',
    isActive: false,
    isOnlyWithVideo: false,
    isOnlyNewBuilding: false,
    prices: { from: 0, to: 0 },
    area: { from: 0, to: 0 },

    setPriceFrom: (priceFrom: number) => set((store) => ({
        prices: { from: priceFrom, to: store.prices.to },
    })),

    setPriceTo: (priceTo: number) => set((store) => ({
        prices: { from: store.prices.from, to: priceTo },
    })),

    setAreaFrom: (areaFrom: number) => set((store) => ({
        area: { from: areaFrom, to: store.area.to },
    })),

    setAreaTo: (areaTo: number) => set((store) => ({
        area: { from: store.area.from, to: areaTo },
    })),

    setFulltext: (fulltext: string) => set(() => ({
        fulltext,
    })),

    setIsOnlyWithVideo: (flag: boolean) => set(() => ({
        isOnlyWithVideo: flag,
    })),

    setIsOnlyNewBuilding: (flag: boolean) => set(() => ({
        isOnlyNewBuilding: flag,
    })),

    setIsActive: (isActive: boolean) => set(() => ({
        isActive: isActive,
    })),
}));