export enum ESefCategories {
    APARTMENTS = 'byty',
    HOUSES = 'domy',
    RECREATIONAL_HOUSES = 'rekreacne-domy',
    LANDS = 'pozemky',
    SPACES = 'nebytove-priestory',
    OBJECTS = 'objekty',
    STUDIO_APARTMENT = 'garsonky',
    DOUBLE_STUDIO_APARTMENT = 'dvojgarsonky',
    ONE_ROOM_APARTMENT = '1-izbove-byty',
    TWO_ROOM_APARTMENT = '2-izbove-byty',
    THREE_ROOM_APARTMENT = '3-izbove-byty',
    FOUR_ROOM_APARTMENT = '4-izbove-byty',
    FIVE_PLUS_ROOM_APARTMENT = '5-a-viac-izbove-byty',
    HOLIDAY_APARTMENT = 'apartmany',
    LOFT = 'lofty',
    MAISONETTE = 'mezonety',
    OTHER_TYPE_OF_APARTMENT = 'ine-byty',
    OTHER_LAND_TO_BUILDING = 'ine-pozemky-na-stavbu',
    OTHER_AGRICULTURAL_LAND = 'ine-polnohospodarske-pozemky',
    FAMILY_HOUSE = 'rodinne-domy',
    COUNTRY_HOUSE = 'vidiecke-domy',
    FARM_SETTLEMENT = 'usadlosti',
    MOBILE_HOUSE = 'mobilne-domy',
    HOUSEBOAT = 'hausboty',
    OTHER_OBJECT_FOR_HOUSING = 'ine-objekty-na-byvanie',
    CABIN_AND_LOG_CABIN = 'chaty-drevenice-zruby',
    COTTAGE_AND_RECREATION_HOUSE = 'chalupy-rekreacne-domceky',
    COTTAGES_AND_CABINS = 'chaty-drevenice',
    OTHER_RECREATIONAL_OBJECT = 'ine-objekty-na-rekreaciu',
    LAND_FOR_FAMILY_HOUSE = 'pozemky-rodinne-domy',
    RECREATIONAL_LAND = 'rekreacne-pozemky',
    LAND_FOR_HOUSING_CONSTRUCTION = 'pozemky-bytova-vystavba',
    LAND_FOR_CIVIC_AMENITIES = 'pozemky-obcianska-vybavenost',
    COMMERCIAL_ZONE = 'pozemky-komercna-zona',
    INDUSTRIAL_ZONE = 'pozemky-priemyselna-zona',
    MIXED_ZONE = 'pozemky-zmiesana-zona',
    GARDEN_HUT = 'zahradne-chatky',
    GARDEN = 'zahrady',
    ARABLE_LAND = 'orne-pody',
    ORCHARD = 'sady',
    MEADOW_AND_PASTURE = 'luky-pasienky',
    VINEYARD_AND_HOP_GARDEN = 'chmelnice-vinice',
    FOREST_LAND = 'lesy',
    POND_AND_LAKE = 'rybniky',
    OTHER_TYPE_OF_LAND = 'ine-polnohospodarske-pozemky',
    LAND_FOR_ADVERTISING = 'pozemky-pre-reklamu',
    CONSOLIDATED_GROUND = 'konsolidovany-pozemok',
    OFFICES = 'kancelarie',
    BUSINESS_SPACES = 'obchodne-priestory',
    RESTAURANT_SPACES = 'restauracne-priestory',
    SPORTS_FACILITIES = 'sportove-priestory',
    STORAGE_AREAS = 'skladove-priestory',
    SPACE_FOR_PRODUCTION = 'vyrobne-priestory',
    REPAIR_AREA = 'opravarenske-priestory',
    SPACE_FOR_ADVERTISEMENT = 'plochy-pre-reklamu',
    //@ TODO ine komercne priestory alebo ine prevadzkove priestory (na PLT je to iba "ine priestory")
    OTHER_TYPE_OF_SPACE = 'ine-priestory',
    APARTMENT_HOUSE = 'apartmanove-domy',
    RENTAL_HOUSE = 'najomne-domy',
    OFFICE_BUILDING = 'administrativne-objekty',
    COMMERCIAL_OBJECT = 'objekty-pre-obchod',
    RESTAURANT = 'restauracie',
    POLYFUNCTIONAL_BUILDING = 'polyfunkcne-objekty',
    WAREHOUSE = 'skladove-objekty',
    MANUFACTURING_FACILITY = 'vyrobne-objekty',
    REPAIR_FACILITY = 'opravarenske-objekty',
    OBJECTS_AND_SPACES = 'nebytove-priestory',
    HOTEL_AND_PENSION = 'hotely-penziony',
    SPA = 'kupelne-objekty',
    HISTORICAL_OBJECT = 'historicke-objekty',
    COMMERCIAL_FACILITY = 'prevadzkove-arealy',
    SPORT_OBJECT = 'objekty-pre-sport',
    SMALL_POWER_STATION = 'male-elektrarne',
    GAS_STATION = 'cerpacie-stanice',
    MOBILE_CELLS_AND_STANDS = 'mobilne-bunky-stanky',
    OBJECT_FOR_BREEDING_ANIMALS = 'polnohospodarske-objekty-a-druzstva',
    AGRICULTURAL_OBJECT = 'polnohospodarske-objekty',
    OTHER_TYPE_OF_OBJECT = 'ine-objekty',
    DETACHED_GARAGE = 'garaze',
    REAL_ESTATES = 'nehnutelnosti',

    SECONDARY_HOUSES = 'domy',
    SECONDARY_RECREATIONAL_PROPERTIES = 'rekreacne-nehnutelnosti',
    SECONDARY_LANDS = 'pozemky',
    SECONDARY_SPACES_AND_OBJECTS = 'priestory-a-objekty',

    SECONDARY_STUDIO_APARTMENT = 'garsonky',
    SECONDARY_THREE_ROOM_APARTMENT = '3-izbove-byty',

    SECONDARY_LAND_FOR_FAMILY_HOUSES = 'pozemky-rodinne-domy',
    SECONDARY_RECREATIONAL_LAND = 'rekreacne-pozemky',
    SECONDARY_COMMERCIAL_PLOTS = 'komercne-pozemky',
    SECONDARY_GARDEN = 'zahrady',
    SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS = 'sady-vinice-chmelnice',
    SECONDARY_AGRICULTURAL_AND_FOREST_LAND = 'polnohospodarske-lesne-pozemky',

    SECONDARY_GARAGE_AND_PARKING = 'garaze-parkovanie',
    SECONDARY_OFFICES_AND_ADMINISTRATION = 'kancelarie-administrativa',
    SECONDARY_THE_SHOP = 'obchody',
    SECONDARY_A_RESTAURANT = 'restauracie',
    SECONDARY_WAREHOUSES = 'sklady',
    SECONDARY_PRODUCTION = 'vyroba',
    SECONDARY_ANOTHER_SPACE_AND_OBJECT = 'ine-priestory-a-objekty',
    SECONDARY_HOTEL_GUESTHOUSE = 'hotely-penziony',
}
