import { AdvertisementsListingFilterParametersDtoTransactionEnum } from 'modules/api/generated';
import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

export const transactionEnumsFromDtoRecord: Record<
    AdvertisementsListingFilterParametersDtoTransactionEnum,
    ETransaction
> = {
    [AdvertisementsListingFilterParametersDtoTransactionEnum.Purchase]:
        ETransaction.PURCHASE,
    [AdvertisementsListingFilterParametersDtoTransactionEnum.Hireout]:
        ETransaction.HIREOUT,
    [AdvertisementsListingFilterParametersDtoTransactionEnum.Rent]:
        ETransaction.RENT,
    [AdvertisementsListingFilterParametersDtoTransactionEnum.Exchange]:
        ETransaction.EXCHANGE,
    [AdvertisementsListingFilterParametersDtoTransactionEnum.Auction]:
        ETransaction.AUCTION,
    [AdvertisementsListingFilterParametersDtoTransactionEnum.Sale]:
        ETransaction.SALE,
};
