import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';

interface IClearInput {
    margin?: number;
    onClick: () => void;
}

export const ClearInput: FC<IClearInput> = ({ margin, onClick }) => {

    return <Box
        top='50%'
        zIndex={10}
        right={margin}
        onClick={(event) => {
            event.stopPropagation();
            onClick();
        }}
        position='absolute'
        sx={{
            cursor: 'pointer',
            transform: 'translate(0, -50%)'
        }}
    >
        <Svg icon component={CloseIcon} width={14}/>
    </Box>;
};