import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    Option
} from 'components/advertisementsFilter/modules/extended/components/location/partials/autocomplete/partials/option/Option';

interface IAutocomplete {
    title: string;
    maxHeight?: number;
    options: ILocation[];
    paddingTop?: number;
    onClick: (location: ILocation) => void
}

export const Autocomplete: FC<IAutocomplete> = ({
    title,
    onClick,
    options,
    maxHeight,
    paddingTop,
}) => {

    return <Box paddingTop={paddingTop}>
        {title &&
            <Box
                paddingX={1}
                paddingTop={1}
                paddingBottom={0.5}
            >
                <Text
                    semibold
                    variant='label1'
                    color={(palette) => palette.labelSecondary}
                >
                    {title}
                </Text>
            </Box>
        }

        <Box
            display='flex'
            maxHeight={maxHeight}
            flexDirection='column'
            overflow={{ xs: 'auto', md: 'visible' }}
        >
            {options.map((option) => <Option
                option={option}
                key={option.id}
                onClick={() => {
                    onClick(option);
                }}
            />)}
        </Box>
    </Box>;
};