import { AdvertisementListResultAdvertisementDevProjectDto } from 'modules/api/generated';
import { IAdvertisementsListResultAdvertisementDevProject } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/partials/devProject/IAdvertisementsListResultAdvertisementDevProject';

export const mapDevProjectDtoToIDevProject = (
    dto: AdvertisementListResultAdvertisementDevProjectDto
): IAdvertisementsListResultAdvertisementDevProject => ({
    id: dto.id,
    name: dto.name,
    slugName: dto.slugName,
    ...(!!dto.externalId && {
        externalId: dto.externalId,
    }),
});
