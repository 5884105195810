import { AdvertisementsListingFilterParametersDtoTransactionEnum } from 'modules/api/generated';
import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

export const transactionListingEnumsToDtoRecord: Record<
    ETransaction,
    AdvertisementsListingFilterParametersDtoTransactionEnum
> = {
    [ETransaction.PURCHASE]:
        AdvertisementsListingFilterParametersDtoTransactionEnum.Purchase,
    [ETransaction.HIREOUT]:
        AdvertisementsListingFilterParametersDtoTransactionEnum.Hireout,
    [ETransaction.RENT]:
        AdvertisementsListingFilterParametersDtoTransactionEnum.Rent,
    [ETransaction.EXCHANGE]:
        AdvertisementsListingFilterParametersDtoTransactionEnum.Exchange,
    [ETransaction.AUCTION]:
        AdvertisementsListingFilterParametersDtoTransactionEnum.Auction,
    [ETransaction.SALE]:
        AdvertisementsListingFilterParametersDtoTransactionEnum.Sale,
};
