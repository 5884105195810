import { useState } from 'react';

interface IReturn {
    isOpen: boolean;
    onClose: () => void;
    anchorEl: HTMLElement;
    onOpen?: (target: HTMLElement) => void;
}

export const usePopover = (): IReturn => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const closePopover = (): void => {
        setAnchorEl(null);
    };

    const openPopover = (targetElement: HTMLElement): void => {
        setAnchorEl(targetElement);
    };

    return {
        anchorEl,
        onOpen: openPopover,
        onClose: closePopover,
        isOpen: Boolean(anchorEl),
    };
};