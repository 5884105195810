import { AxiosResponse } from 'axios';
import { advertisementListingApi } from 'modules/api/client';
import {
    AdvertisementListingDevProjectsDto,
    AdvertisementsListDto,
    AdvertisementsListingFilterParametersDto,
} from 'modules/api/generated';
import { IAdvertisementListingFilter } from 'modules/api/clients/advertisement/filter/interfaces/IAdvertisementListingFilter';
import { IAdvertisementsList } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import { IAdvertisementListingFilterPublicData } from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterPublicData';
import { mapAdvertisementsListDtoToIAdvertisementsList } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/mapAdvertisementsListDtoToIAdvertisementsList';
import { mapIAdvertisementListingFilterToAdvertisementListingFilterDto } from 'modules/api/clients/advertisement/filter/helpers/mappers/mapIAdvertisementListingFilterToAdvertisementListingFilterDto';
import { IAdvertisementListingFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { mapAdvertisementListingDevProjectsDtoToDevProject } from 'modules/api/clients/advertisement/listing/helpers/mappers/devProject/mapAdvertisementListingDevProjectsDtoToDevProject';
import { reduceDevProjectsAccordingToCountMatchesFilter } from 'modules/api/clients/advertisement/listing/helpers/reducers/reduceDevProjectsAccordingToCountMatchesFilter';
import { mapIAdvertisementInternalUrlFilterDataToAdvertisementListingFilterDataDto } from 'modules/api/clients/advertisement/filter/helpers/mappers/data/mapIAdvertisementInternalUrlFilterDataToAdvertisementListingFilterDataDto';
import { mapIAdvertisementCountFilterDataToAdvertisementListingFilterDataDto } from 'modules/api/clients/advertisement/filter/helpers/mappers/data/mapIAdvertisementCountFilterDataToAdvertisementListingFilterDataDto';
import { mapAdvertisementListingFilterDataDtoToIAdvertisementListingFilterData } from 'modules/api/clients/advertisement/filter/helpers/mappers/data/mapAdvertisementListingFilterDataDtoToIAdvertisementListingFilterData';

export class AdvertisementListing {
    public async getAdvertisements(
        filter: IAdvertisementListingFilter
    ): Promise<IAdvertisementsList> {
        let response: AxiosResponse<AdvertisementsListDto>;
        const listingFilter =
            mapIAdvertisementListingFilterToAdvertisementListingFilterDto(
                filter
            );
        try {
            response = await advertisementListingApi.getAdvertisements(
                listingFilter
            );
        } catch (error) {
            throw new Error('Advertisements were not found');
        }

        if (response.status !== 201) {
            throw new Error('Advertisements were not found');
        }

        const AdvertisementsListDto =
            mapAdvertisementsListDtoToIAdvertisementsList(response.data);

        const { results } = AdvertisementsListDto;

        const reducedDevProjectsResult =
            reduceDevProjectsAccordingToCountMatchesFilter(results);

        return {
            ...AdvertisementsListDto,
            results: reducedDevProjectsResult,
        };
    }

    public async getAdvertisementsListingPageInternalUrl(
        filter: IAdvertisementListingFilterData
    ): Promise<{url: string, title: string}> {
        let response: AxiosResponse<{url: string, title: string}>;
        const listingFilter =
            mapIAdvertisementInternalUrlFilterDataToAdvertisementListingFilterDataDto(
                filter
            );
        try {
            response = await advertisementListingApi.advListingUrlByInternalFilter(
                listingFilter,
            );
        } catch (error) {
            throw new Error('Filter internal url was not found');
        }

        if (response.status !== 201) {
            throw new Error('Filter internal url was not found');
        }

        const data = {
            url: response.data.url,
            title: response.data.title,
        };

        return data;
    }

    public async getAdvertisementsListingPagePublicUrl(
        filter: IAdvertisementListingFilterPublicData,
    ): Promise<{url: string, title: string}> {
        let response: AxiosResponse<{url: string, title: string}>;

        try {
            response = await advertisementListingApi.advListingUrlByPublicFilter(filter);
        } catch (error) {
            throw new Error('Filter public url was not found');
        }

        if (response.status !== 201) {
            throw new Error('Filter public url was not found');
        }

        const data = {
            url: response.data.url,
            title: response.data.title,
        };

        return data;
    }

    public async getAdvertisementsListingPageFilterByUrl(
        url: string
    ): Promise<IAdvertisementListingFilterData> {
        let response: AxiosResponse<AdvertisementsListingFilterParametersDto>;

        try {
            response = await advertisementListingApi.advListingInternalFilterByUrl(url);
        } catch (error) {
            throw new Error('Filter by url was not found');
        }

        if (response.status !== 200) {
            throw new Error('Filter by url was not found');
        }

        return mapAdvertisementListingFilterDataDtoToIAdvertisementListingFilterData(response.data);
    }

    public async getAdvertisementsTotalCount(
        filter: IAdvertisementListingFilterData
    ): Promise<number> {
        let response: AxiosResponse<number>;
        const countFilter =
            mapIAdvertisementCountFilterDataToAdvertisementListingFilterDataDto(
                filter
            );

        try {
            response = await advertisementListingApi.getAdvertisementsCount(
                countFilter
            );
        } catch (error) {
            throw new Error('Cannot get advertisements total count by filter');
        }

        if (response.status !== 201) {
            throw new Error('Cannot get advertisements total count by filter');
        }

        return response.data;
    }

    public async getTotalRoundedAdvertisementsCount(): Promise<number> {
        let response: AxiosResponse<number>;

        try {
            response =
                await advertisementListingApi.getTotalRoundedAdvertisementsCount();
        } catch (error) {
            throw new Error(
                `Failed to get total rounded advertisements count. Error: ${error.message}`
            );
        }

        return response.data;
    }

    public async getDevProjects(
        limit: number,
        skip: number
    ): Promise<IDevProject[]> {
        let response: AxiosResponse<AdvertisementListingDevProjectsDto>;

        try {
            response = await advertisementListingApi.getDevProjects(
                limit,
                skip
            );
        } catch (error) {
            throw new Error('Cannot get Dev Projects');
        }

        if (response.status !== 200) {
            throw new Error('Cannot get Dev Projects');
        }

        const {
            data: { projects },
        } = response;

        return projects.map(mapAdvertisementListingDevProjectsDtoToDevProject);
    }
}
