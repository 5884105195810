import { FC } from 'react';
import { PaletteColor, Palette } from '@mui/material';
import {Text as DSText} from 'modules/theme/components/text/Text';

interface IText {
    text: string;
    semibold?: boolean;
    variant: 'body2' | 'label2';
    color: (palette: Palette) => PaletteColor;
}

export const Text: FC<IText> = ({
    text,
    color,
    variant,
    semibold,
}) => {

    return <DSText
        color={color}
        variant={variant}
        semibold={semibold}
    >
        {text}
    </DSText>;
};