import { useEffect } from 'react';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const usePrices = (
    pricesFromFilter: { from: number, to: number},
): void => {
    const setPriceTo = useExtendedStore((store) => store.setPriceTo);
    const setPriceFrom = useExtendedStore((store) => store.setPriceFrom);

    useEffect(() => {
        if (pricesFromFilter.from) {
            setPriceFrom(pricesFromFilter.from);
        }

        if (pricesFromFilter.to) {
            setPriceTo(pricesFromFilter.to);
        }
    }, [
        pricesFromFilter.from,
        pricesFromFilter.to,
        setPriceFrom,
        setPriceTo,
    ]);
};