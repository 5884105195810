import { useCallback } from 'react';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';

interface IReturn {
    syncFilterWithStore: (
        dataFromUrl: FilterDataFromUrlType,
    ) => void;
}

export const useSyncFilterWithStore = (): IReturn => {

    const setSelectedLocations = useLocationStore((store) => store.setSelectedLocations);
    const setSelectedTransaction = useTransactionStore((store) => store.setSelectedTransaction);
    const setSelectedCategories = useCategoriesStore((store) => store.setSelectedCategories);

    const syncFilterWithStore = useCallback((
        dataFromUrl: FilterDataFromUrlType,
    ): void => {
        if (!dataFromUrl) return;

        if (dataFromUrl.transaction) {
            setSelectedTransaction(dataFromUrl.transaction.id);
        }

        if (dataFromUrl.categories && dataFromUrl.categories.length) {
            setSelectedCategories(dataFromUrl.categories);

        }

        if (dataFromUrl.locations && dataFromUrl.locations.length) {
            setSelectedLocations(dataFromUrl.locations);
        }
    }, [setSelectedCategories, setSelectedLocations, setSelectedTransaction]);

    return {
        syncFilterWithStore,
    };
};