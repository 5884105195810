import { AdvertisementListing } from 'modules/api/clients/advertisement/listing/AdvertisementsListing';
import {
    IAdvertisementListingFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';

export const fetchAdvertisementsTotalCount = async (
    filter: IAdvertisementListingFilterData
): Promise<number> => {
    try {
        return await new AdvertisementListing().getAdvertisementsTotalCount(filter);
    } catch (error) {
        throw new Error(
            `Failed to fetch advertisements total count. Error: ${error.message}`
        );
    }
};