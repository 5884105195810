import {
    AdvertisementListResultLocationDto,
} from 'modules/api/generated';
import {
    IAdvertisementListResultLocation
} from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/common/location/name/IAdvertisementListResultLocation';

export const mapAdvertisementListResultLocationToILocation =
    (dto: AdvertisementListResultLocationDto): IAdvertisementListResultLocation => ({
        name: dto.name,
        country: dto.country || null,
        county: dto.county || null,
        district: dto.district || null,
        city: dto.city || null,
    });
