import { FC } from 'react';
import { Box } from '@mui/material';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import { RadioBox } from 'modules/theme/components/fields/radioBox/RadioBox';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { ETransactionName } from 'components/advertisementsFilter/modules/transaction/enums/ETransactionName';

export const Transaction: FC = () => {
    const transactionOptions = useTransactionStore((store) => store.transactionOptions);
    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);
    const setSelectedTransaction = useTransactionStore((store) => store.setSelectedTransaction);

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Ponuka'/>
        </Box>

        <Box
            width='100%'
            display='flex'
            alignItems='center'
        >
            {transactionOptions.map((option) =>
                <RadioBox
                    key={option.id}
                    value={option.name}
                    label={ETransactionName[option.name]}
                    onChange={() => {
                        setSelectedTransaction(option.id);
                    }}
                    isChecked={selectedTransaction?.id === option.id}
                />
            )}
        </Box>
    </ContentItemWrapper>;
};