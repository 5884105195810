import { AdvertisementsListResultDevProjectFlagsDto } from 'modules/api/generated';
import { IAdvertisementsListResultDevProjectFlags } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/devProject/partials/flags/IAdvertisementsListResultDevProjectFlags';

export const mapFlagsDtoToIFlags = (
    dto: AdvertisementsListResultDevProjectFlagsDto
): IAdvertisementsListResultDevProjectFlags => ({
    isTop: dto.isTop,
    isPremium: dto.isPremium,
    isProfi: dto.isProfi,
});
