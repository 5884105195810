import { AdvertisementListingDevProjectsDevProjectDto } from 'modules/api/generated';
import { IDevProject } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/IDevProject';
import { mapFlagsDtoToIFlags } from 'modules/api/clients/advertisement/listing/helpers/mappers/devProject/flags/mapFlagsDtoToIFlags';
import { mapPriceInfoDtoToIPriceInfo } from 'modules/api/clients/advertisement/listing/helpers/mappers/devProject/price/mapPriceInfoDtoToIPriceInfo';
import { mapAdvertiserDtoToIAdvertiser } from 'modules/api/clients/advertisement/listing/helpers/mappers/devProject/advertiser/mapAdvertiserDtoToIAdvertiser';
import { mapLocationInfoDtoToILocationInfo } from 'modules/api/clients/advertisement/listing/helpers/mappers/devProject/locationInfo/mapLocationInfoDtoToILocationInfo';
import { mapAdvertisementsInfoDtoToIAdvertisementsInfo } from 'modules/api/clients/advertisement/listing/helpers/mappers/devProject/advertisementsInfo/mapAdvertisementsInfoDtoToIAdvertisementsInfo';
export const mapAdvertisementListingDevProjectsDtoToDevProject = (
    dto: AdvertisementListingDevProjectsDevProjectDto
): IDevProject => ({
    id: dto.id,
    title: dto.title,
    sefName: dto.sefName,
    isTop: dto.flags.isTop,
    transaction: dto.transaction,
    isPremium: dto.flags.isPremium,
    devProjectDetailUrl: dto.detailUrl,
    realEstateState: dto.realEstateState,
    flags: mapFlagsDtoToIFlags(dto.flags),
    ...(!!dto.photoUrl && { photoUrl: dto.photoUrl }),

    priceInfo: mapPriceInfoDtoToIPriceInfo(dto.priceInfo),
    advertiser: mapAdvertiserDtoToIAdvertiser(dto.advertiser),
    locationInfo: mapLocationInfoDtoToILocationInfo(dto.locationInfo),
    advertisementsInfo: mapAdvertisementsInfoDtoToIAdvertisementsInfo(
        dto.advertisementsInfo
    ),
});
