import {
    AdvertisementsListResultPriceInfoPriceDto,
    AdvertisementListingPriceInfoDtoPriceDto,
    AdvertisementListingPriceInfoDtoPriceOptionEnum,
    AdvertisementDetailAdvertisementPriceInfoDtoPriceOptionEnum,
} from 'modules/api/generated';
import { priceCurrencyEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/parameters/price/priceCurrencyEnumsRecord';
import { priceUnitsEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/parameters/price/priceUnitsEnumsRecord';
import { priceOptionEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/parameters/price/priceOptionEnumsRecord';
import { IPriceInfoDetail } from 'modules/api/clients/advertisement/common/interfaces/price/IPriceInfoDetail';
import { formatPrice } from 'modules/api/clients/advertisement/common/helpers/formatPrice/formatPrice';

export const mapPrimaryAndSecondaryPrices = (
    pricePrimary: AdvertisementsListResultPriceInfoPriceDto | AdvertisementListingPriceInfoDtoPriceDto,
    priceSecondary: AdvertisementsListResultPriceInfoPriceDto | AdvertisementListingPriceInfoDtoPriceDto,
    priceOption: AdvertisementListingPriceInfoDtoPriceOptionEnum | AdvertisementDetailAdvertisementPriceInfoDtoPriceOptionEnum,
): IPriceInfoDetail  => {
    let price = undefined;
    let priceNum = undefined;
    let unitPrice = undefined;
    let priceValue = undefined;
    let hasPriceOption = false;
    let currency = undefined;

    if (pricePrimary) {
        currency = priceCurrencyEnumsRecord[pricePrimary.currency];
        const unit = priceUnitsEnumsRecord[pricePrimary.unit];
        priceNum = pricePrimary.value;

        price = formatPrice(pricePrimary.value, currency, unit);
    }

    if (priceOption) {
        price = priceOptionEnumsRecord[priceOption];
        hasPriceOption = true;
    }

    if (priceSecondary) {
        currency = priceCurrencyEnumsRecord[priceSecondary.currency];
        const unit = priceUnitsEnumsRecord[priceSecondary.unit];

        unitPrice = formatPrice(priceSecondary.value, currency, unit);
    }

    if (pricePrimary?.value) {
        priceValue = pricePrimary?.value.toString();
    }

    return {
        price,
        currency,
        priceNum,
        unitPrice,
        priceValue,
        hasPriceOption,
    };
};
