import { useMemo } from 'react';

interface IReturn {
    first: { isHidden: boolean };
    second: { isHidden: boolean }
}

export const useVerticalDivider = (
    isLocationHovered: boolean,
    isCategoryHovered: boolean,
    isLocationInFocus: boolean,
    isCategoryInFocus: boolean,
    isTransactionHovered: boolean,
    isTransactionInFocus: boolean,
): IReturn => {

    const isFirstHidden = useMemo(() => {
        const isLocationHoveredOrInFocus = isLocationHovered || isLocationInFocus;
        const isTransactionHoveredOrInFocus = isTransactionHovered || isTransactionInFocus;

        return isLocationHoveredOrInFocus || isTransactionHoveredOrInFocus;
    }, [
        isLocationHovered,
        isLocationInFocus,
        isTransactionHovered,
        isTransactionInFocus,
    ]);

    const isSecondHidden = useMemo(() => {
        const isTransactionHoveredOrInFocus = isTransactionHovered || isTransactionInFocus;
        const isCategoryHoveredOrInFocus = isCategoryHovered || isCategoryInFocus;

        return isTransactionHoveredOrInFocus || isCategoryHoveredOrInFocus;
    }, [
        isTransactionHovered,
        isTransactionInFocus,
        isCategoryHovered,
        isCategoryInFocus,
    ]);

    return {
        first: { isHidden: isFirstHidden },
        second: { isHidden: isSecondHidden },
    };
};