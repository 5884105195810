import { AdvertisementsListingFilterParametersDtoOrderEnum } from 'modules/api/generated';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';

export const orderEnumToDtoRecord: Record<
    EOrder,
    AdvertisementsListingFilterParametersDtoOrderEnum
> = {
    [EOrder.DEFAULT]: AdvertisementsListingFilterParametersDtoOrderEnum.Default,
    [EOrder.NEWEST]: AdvertisementsListingFilterParametersDtoOrderEnum.Newest,
    [EOrder.PRICE_ASC]:
        AdvertisementsListingFilterParametersDtoOrderEnum.PriceAsc,
    [EOrder.PRICE_DESC]:
        AdvertisementsListingFilterParametersDtoOrderEnum.PriceDesc,
};
