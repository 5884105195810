import {
    RealEstateAdvertisementListingAdvertisementDtoTransactionEnum,
} from 'modules/api/generated';
import {
    ETransaction
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/transaction/ETransaction';

export const DtoTransactionEnumToETransaction: Record<RealEstateAdvertisementListingAdvertisementDtoTransactionEnum, ETransaction> = {
    [RealEstateAdvertisementListingAdvertisementDtoTransactionEnum.Sale]: ETransaction.SALE,
    [RealEstateAdvertisementListingAdvertisementDtoTransactionEnum.Purchase]: ETransaction.PURCHASE,
    [RealEstateAdvertisementListingAdvertisementDtoTransactionEnum.Hireout]: ETransaction.HIREOUT,
    [RealEstateAdvertisementListingAdvertisementDtoTransactionEnum.Rent]: ETransaction.RENT,
    [RealEstateAdvertisementListingAdvertisementDtoTransactionEnum.Auction]: ETransaction.AUCTION,
    [RealEstateAdvertisementListingAdvertisementDtoTransactionEnum.Exchange]: ETransaction.EXCHANGE,
};
