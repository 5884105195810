import { FC } from 'react';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { useSearchFilter } from 'components/advertisementsFilter/modules/common/hooks/useSearchFilter';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useAdvertisementsTotalCountByFilter } from 'components/advertisementsFilter/features/totalCount/hooks/useTotalCount';
import { useAdvertisementsRedirectUrl } from 'components/advertisementsFilter/features/search/hooks/useRedirectUrl';
import { useSearch } from 'components/advertisementsFilter/features/search/hooks/useSearch';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';

interface IParametersSetter {
    filterDataFromUrl: FilterDataFromUrlType;
    pagination: IAdvertisementListingFilterPagination;
    initialSearchFilterData: IInitialSearchFilterData;
}

export const ParametersSetter: FC<IParametersSetter> = ({
    pagination,
    filterDataFromUrl,
    initialSearchFilterData,
}) => {
    useSearchFilter(initialSearchFilterData, filterDataFromUrl);

    useAdvertisementsTotalCountByFilter();
    useAdvertisementsRedirectUrl();

    useSearch(
        initialSearchFilterData,
        pagination,
        filterDataFromUrl,
    );

    return null;
};