import { AdvertisementBreadcrumbItemDto } from 'modules/api/generated';
import { IBreadcrumb } from 'modules/api/clients/advertisement/common/interfaces/breadcrumb/partials/part/IBreadcrumb';
import { mapAdvertisementBreadcrumbItemLocationDtoToIBreacrumbLocation } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/breadcrumb/location/mapAdvertisementBreadcrumbItemLocationDtoToIBreadcrumbLocation';
import { mapAdvertisementBreadcrumbItemCategoryDtoToIBreadcrumbCommonParameter } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/breadcrumb/category/mapAdvertisementBreadcrumbItemCategoryDtoToIBreadcrumbCommonParameter';
import { mapAdvertisementBreadcrumbItemTransactionDtoToIBreadcrumbCommonParameter } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/breadcrumb/transaction/mapAdvertisementBreadcrumbItemTransactionDtoToIBreadcrumbCommonParameter';

export const mapAdvertisementBreadcrumbItemDtoToIBreadcrumb = (advertisementBreadcrumbItemDto: AdvertisementBreadcrumbItemDto): IBreadcrumb => {
    return {
        location: advertisementBreadcrumbItemDto?.location ? mapAdvertisementBreadcrumbItemLocationDtoToIBreacrumbLocation(advertisementBreadcrumbItemDto?.location) : undefined,
        category: advertisementBreadcrumbItemDto?.category ? mapAdvertisementBreadcrumbItemCategoryDtoToIBreadcrumbCommonParameter(advertisementBreadcrumbItemDto?.category) : undefined,
        transaction: advertisementBreadcrumbItemDto?.transaction ? mapAdvertisementBreadcrumbItemTransactionDtoToIBreadcrumbCommonParameter(advertisementBreadcrumbItemDto?.transaction): undefined
    };
};