import React, { FC } from 'react';
import { Box, Palette, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import CircleIcon from 'modules/theme/components/icons/basic/Circle.svg';
import CheckCircleIcon from 'modules/theme/components/icons/basic/CheckCircle.svg';
import {
    Text
} from 'components/advertisementsFilter/modules/transaction/components/common/completeBox/partials/item/partials/text/Text';

interface ITransactionItem {
    id: number;
    title: string;
    isFirst: boolean;
    isSelected: boolean;
    description: string;
    onChange: (id: number) => void;
}

export const TransactionItem: FC<ITransactionItem> = ({
    id,
    title,
    onChange,
    isSelected,
    description,
}) => {

    return (
        <Box
            py={1.5}
            display="flex"
            alignItems="center"
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: (theme: Theme) => theme.palette.backgroundSecondary.main,
                },
            }}
            px={{
                xs: 2,
                md: 4
            }}
            onClick={() => onChange(id)}
            justifyContent="space-between"
        >
            <Box minWidth={200} display="flex" flexDirection="column">
                <Text
                    semibold
                    text={title}
                    variant="body2"
                    color={(palette) => palette.labelPrimary}
                />

                <Text
                    text={description}
                    variant="label2"
                    color={(palette: Palette) => palette.labelSecondary}
                />
            </Box>

            <Svg
                icon
                width={24}
                color={isSelected ? 'inherit' : 'labelTertiary'}
                component={isSelected ? CheckCircleIcon : CircleIcon}
            />
        </Box>
    );
};
