import { FC } from 'react';
import { Box } from '@mui/material';
import {
    TransactionItem
} from 'components/advertisementsFilter/modules/transaction/components/common/completeBox/partials/item/TransactionItem';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import { ETransactionName } from 'components/advertisementsFilter/modules/transaction/enums/ETransactionName';
import { ETransactionDescription } from 'components/advertisementsFilter/modules/transaction/enums/ETransactionDescription';

interface ICompleteBox {
    transaction: number;
    options: ITransaction[];
    onTransactionChange: (value: number) => void;
}

export const CompleteBox: FC<ICompleteBox> = ({
    options,
    transaction,
    onTransactionChange
}) => {

    return <Box
        display='flex'
        flexDirection='column'
        width='100%'
        sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
                width: 'calc(100% + 32px)',
                mx: -2,
            }
        })}
    >
        {options.map((option, index) =>
            <TransactionItem
                id={option.id}
                key={option.id}
                isFirst={index == 0}
                onChange={onTransactionChange}
                title={ETransactionName[option.name]}
                isSelected={transaction === option.id}
                description={ETransactionDescription[option.name]}
            />
        )}
    </Box>;
};