import React, {FC, FunctionComponent, SVGProps} from 'react';
import {Box, useMediaQuery, Theme} from '@mui/material';
import {
    RadioCardContent
} from 'components/advertisementsFilter/modules/categories/components/common/completeBox/partials/mainCategories/partials/radioCard/content/RadioCardContent';

interface ICategoryRadioCard {
    label: string;
    small?: boolean;
    noTitle?: boolean;
    isChecked: boolean;
    fullWidth?: boolean;
    onClick: () => void;
    width?: string | number;
    height?: number | string;
    titleHeight?: string | number;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const CategoryRadioCard: FC<ICategoryRadioCard> = ({
    icon,
    small,
    label,
    width,
    height,
    noTitle,
    onClick,
    fullWidth,
    isChecked,
}) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const isSmDown = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    if (!width) {
        width = fullWidth ? '100%' : small ? 48 : isSmDown ? 'calc(33% - 5px)' : 124;
    }

    return (
        <Box
            p={1.5}
            gap={0.5}
            tabIndex={-1}
            display='flex'
            height={height}
            onClick={onClick}
            textAlign='center'
            alignItems='center'
            borderRadius='12px'
            flexDirection='column'
            justifyContent='center'
            width={width}
            data-test-id='categoryRadioCardCustom'
            sx={(theme) => ({
                cursor: 'pointer',
                '& .MuiSvgIcon-root': {
                    color: theme.palette.labelPrimary.main,
                },
                outline: isChecked
                    ? `1px solid ${theme.palette.labelPrimary.main}`
                    : `1px solid ${theme.palette.dividerTransparent}`,
                backgroundColor: isChecked
                    ? theme.palette.backgroundSecondary.main
                    : theme.palette.backgroundPrimary.main,
                border: isChecked
                    ? `1px solid ${theme.palette.labelPrimary.main}`
                    : '1px solid transparent',
                '&:hover': {
                    border: `1px solid ${theme.palette.labelPrimary.main}`,
                    outline: `1px solid ${theme.palette.labelPrimary.main}`,
                    backgroundColor: !isMobile && theme.palette.backgroundSecondary.main
                },
                '@media (hover: none)': {
                    '&:hover': {
                        outline: isChecked
                            ? `1px solid ${theme.palette.labelPrimary.main}`
                            : `1px solid ${theme.palette.dividerTransparent}`,
                        border: isChecked
                            ? `1px solid ${theme.palette.labelPrimary.main}`
                            : '1px solid transparent',
                    },
                },
                ...(isMobile && {
                    '& .MuiTypography-root': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical'
                    }

                }),
            })}
        >
            <RadioCardContent
                icon={icon}
                label={label}
                iconWidth={24}
                noTitle={noTitle}
                noWrap={!isMobile}
                isTextBold={isChecked}
            />
        </Box>
    );
};