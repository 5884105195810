import { Location } from 'modules/api/clients/location/Location';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

export const fetchLocationSuggestions = async (
    query: string,
    limit: number
): Promise<ILocation[]> => {
    try {
        return await new Location().getSuggestions(query, limit);
    } catch (error) {
        throw new Error(
            `Failed to fetch location suggestions. Error: ${error.message}`
        );
    }
};
