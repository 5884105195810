import {
    AdvertiserDto,
    AdvertisementsListResultAdvertiserDto,
} from 'modules/api/generated';
import { advertiserTypeEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/advertiser/advertiserTypeEnumsRecord';
import { mapLocationDtoToILocation } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/common/location/mapLocationDtoToILocation';
import { IAdvertisementsListResultAdvertisementAdvertiser } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/common/advertiser/IAdvertisementsListResultAdvertisementAdvertiser';

export const mapAdvertiserDtoToIAdvertiser = (
    dto: AdvertisementsListResultAdvertiserDto | AdvertiserDto
): IAdvertisementsListResultAdvertisementAdvertiser => ({
    id: dto.id,
    name: dto.name,
    isPro: dto.isPro,
    ...(!!dto.webPage && { webPage: dto.webPage }),
    ...(!!dto.photoUrl && { photoUrl: dto.photoUrl }),

    location: mapLocationDtoToILocation(dto.location),

    type: advertiserTypeEnumsRecord[dto.type] || 'AGENCY', //@TODO
    ...(!!dto.parent && { parent: mapAdvertiserDtoToIAdvertiser(dto.parent) }),
});
