import { AdvertisementListResultAdvertisementParametersDto } from 'modules/api/generated';
import { IAdvertisementsListResultParameter } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/common/parameters/IAdvertisementsListResultParameter';
import { mapCategoryDtoToICategory } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/common/parameters/category/mapCategoryDtoToICategory';

export const mapParametersDtoToIParameter = (
    dto: AdvertisementListResultAdvertisementParametersDto
): IAdvertisementsListResultParameter => ({
    transaction: dto.transaction,

    ...(!!dto.area && { area: dto.area }),
    ...(!!dto.realEstateState && { realEstateState: dto.realEstateState }),
    ...(!!dto.totalRoomsCount && { totalRoomsCount: dto.totalRoomsCount }),

    category: mapCategoryDtoToICategory(dto.category),
});
