import { FC } from 'react';
import { Box } from '@mui/material';
import SearchIcon from 'modules/theme/components/icons/basic/Search.svg';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Button } from 'modules/theme/components/button/Button';
import { Text } from 'modules/theme/components/text/Text';
import {
    lemmatizeWordAccordingToNumber
} from 'modules/lemmatize/lemmatizeWordAccordingToNumber';

interface IDialogFooter {
    totalCount: number;
    onRedirectByFilter: () => void;
    onSearchFilterReset: () => void;
}

export const DialogFooter: FC<IDialogFooter> = ({
    totalCount,
    onRedirectByFilter,
    onSearchFilterReset,
}) => {

    const lemmetizedWord = lemmatizeWordAccordingToNumber(totalCount, {
        eqOne: 'ponuka',
        eqZero: 'ponúk',
        betweenTwoAndFourInclusive: 'ponúky',
        moreThenFour: 'ponúk',
    });

    return <Box
        gap={1}
        zIndex={2}
        bottom={0}
        width='100%'
        display='flex'
        alignItems='center'
        px={{ xs: 2 }}
        py={{ xs: 1.5, md: 2 }}
        justifyContent='space-between'
        position={{xs: 'fixed', md: 'sticky'}}
        bgcolor={(theme) => theme.palette.backgroundPrimary.main}
        borderTop={(theme) => `1px solid ${theme.palette.dividerTransparent}`}
    >
        <Button
            quaternary
            onClick={onSearchFilterReset}
            text={(
                <Text variant='body2' underLine>Vymazať</Text>
            )}
        />

        <Button
            onClick={onRedirectByFilter}
            text={(
                <Box display='flex' alignItems='center' gap={1}>
                    <Svg icon component={SearchIcon} width={24}/>

                    <Text
                        semibold
                        variant='body2'
                        color={(palette) => palette.labelPrimaryInverse}
                    >
                        {lemmetizedWord}
                    </Text>
                </Box>

            )}
        />
    </Box>;
};
