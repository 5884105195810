import { FC } from 'react';
import { Box } from '@mui/material';
import {
    VerticalDivider
} from 'components/advertisementsFilter/components/desktop/partials/verticalDivider/VerticalDivider';
import { Transaction } from 'components/advertisementsFilter/modules/transaction/components/desktop/Transaction';
import {
    DesktopFilterWrapper
} from 'components/advertisementsFilter/components/desktop/partials/buttons/partials/wrapper/FilterWrapper';
import { Location } from 'components/advertisementsFilter/modules/location/components/desktop/Location';
import { Category } from 'components/advertisementsFilter/modules/categories/components/desktop/Category';

import { useInputFocus } from 'components/advertisementsFilter/modules/common/hooks/inputs/useInputFocus';
import { useSelectNextInput } from 'components/advertisementsFilter/modules/common/hooks/inputs/useSelectNextInput';
import { usePopover } from 'components/advertisementsFilter/modules/common/hooks/popover/usePopover';
import { useVerticalDivider } from 'components/advertisementsFilter/modules/common/hooks/verticalDivider/useVerticalDivider';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';
import { EInputs } from 'components/advertisementsFilter/modules/common/enums/EInputs';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { Buttons } from 'components/advertisementsFilter/components/desktop/partials/buttons/Buttons';

interface IDesktopFilter {
    isExtendedDialogOpened: boolean;
    onExtendedDialogOpen: () => void;
}

export const DesktopFilter: FC<IDesktopFilter> = ({
    onExtendedDialogOpen,
    isExtendedDialogOpened,
}) => {
    const onSelectLocation = useLocationStore((store) => store.onSelectLocation);

    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);
    const setSelectedTransaction = useTransactionStore((store) => store.setSelectedTransaction);

    const {
        anchorEl: anchorElTransaction,
        isOpen: isOpenTransaction,
        onOpen: onOpenTransaction,
        onClose:  onCloseTransaction
    } = usePopover();
    const {
        anchorEl: anchorElCategory,
        isOpen: isOpenCategory,
        onOpen: onOpenCategory,
        onClose: onCloseCategory
    } = usePopover();
    const {
        anchorEl: anchorElLocation,
        onOpen: onOpenLocation,
        isOpen: isOpenLocation,
        onClose: onCloseLocation
    } = usePopover();

    const {
        isNothingInFocus,
        isLocationHovered,
        isCategoryHovered,
        isLocationInFocus,
        isCategoryInFocus,
        onChangeHoverState,
        isTransactionHovered,
        isTransactionInFocus,
        onChangeInputInFocus,
    } = useInputFocus(
        isOpenLocation,
        isOpenTransaction,
        isOpenCategory,
    );

    const { onSelectNext, onStayFocused } = useSelectNextInput(
        onOpenCategory,
        onOpenLocation,
        onOpenTransaction,
        onChangeInputInFocus,
    );

    const { first, second } = useVerticalDivider(
        isLocationHovered,
        isCategoryHovered,
        isLocationInFocus,
        isCategoryInFocus,
        isTransactionHovered,
        isTransactionInFocus,
    );

    return <DesktopFilterWrapper
        isFocused={!isNothingInFocus}
        maxWidth={774}
        height={76}
    >
        <Box
            display='flex'
            paddingLeft='3px'
            alignItems='center'
        >
            <Location
                isHovered={isLocationHovered}
                isInFocus={isLocationInFocus}
                isPopoverOpen={isOpenLocation}
                onOpenPopover={onOpenLocation}
                onClosePopover={() => {
                    if (selectedTransaction && isLocationHovered) return;
                    onCloseLocation();
                }}
                popoverAnchorEl={anchorElLocation}
                onChangeHoverState={onChangeHoverState}
                onSetInputInFocus={onChangeInputInFocus}
                onChangeLocation={(location) => {
                    onSelectLocation(location);
                    onStayFocused(EInputs.LOCATION);
                }}
            />

            <VerticalDivider isHidden={first.isHidden}/>

            <Transaction
                isHovered={isTransactionHovered}
                isInFocus={isTransactionInFocus}
                isPopoverOpen={isOpenTransaction}
                onOpenPopover={onOpenTransaction}
                onClosePopover={onCloseTransaction}
                popoverAnchorEl={anchorElTransaction}
                onChangeHoverState={onChangeHoverState}
                onSetInputInFocus={onChangeInputInFocus}
                onChangeTransaction={(transaction) => {
                    setSelectedTransaction(transaction);

                    if (transaction) onSelectNext(EInputs.CATEGORY);
                }}
            />

            <VerticalDivider isHidden={second.isHidden}/>

            <Category
                isHovered={isCategoryHovered}
                isInFocus={isCategoryInFocus}
                isPopoverOpen={isOpenCategory}
                onOpenPopover={onOpenCategory}
                onClosePopover={onCloseCategory}
                popoverAnchorEl={anchorElCategory}
                onChangeHoverState={onChangeHoverState}
                onSetInputInFocus={onChangeInputInFocus}
            />
        </Box>

        <Box>
            <Buttons
                onExtendedDialogOpen={onExtendedDialogOpen}
                isExtendedDialogOpened={isExtendedDialogOpened}
            />
        </Box>
    </DesktopFilterWrapper>;
};
