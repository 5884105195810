import { useCallback, useState, useEffect } from 'react';
import { EInputs } from 'components/advertisementsFilter/modules/common/enums/EInputs';
import {
    useLocationInputStore
} from 'components/advertisementsFilter/modules/location/store/useLocationInputStore';

interface IReturn {
    onSelectNext: (nextInput: EInputs) => void;
    onStayFocused: (nextInput: EInputs) => void;
}

export const useSelectNextInput = (
    onOpenCategory: (target: HTMLElement) => void,
    onOpenLocation: (target: HTMLElement) => void,
    onOpenTransaction: (target: HTMLElement) => void,
    onChangeInputInFocus: (value: EInputs) => void,
): IReturn => {
    const [anchorTargetElLocation, setAnchorTargetElLocation] = useState<HTMLElement>();
    const [anchorTargetCategoryEvent, setAnchorTargetElCategory] = useState<HTMLElement>();
    const [anchorTargetElTransaction, setAnchorTargetElTransaction] = useState<HTMLElement>();

    const locationInputRef = useLocationInputStore((store) => store.locationInputRef);

    useEffect(() => {
        const categoryInput = document.getElementById('filter.categoryInput');
        const locationInput = document.getElementById('filter.locationInput');
        const transactionInput = document.getElementById('filter.transactionInput');

        if (categoryInput) setAnchorTargetElCategory(categoryInput);
        if (locationInput) setAnchorTargetElLocation(locationInput);
        if (transactionInput) setAnchorTargetElTransaction(transactionInput);
    }, []);

    const onSelectNext = useCallback((nextInput: EInputs) => {
        if (nextInput === EInputs.TRANSACTION) {
            onOpenTransaction(anchorTargetElTransaction);
            onChangeInputInFocus(EInputs.TRANSACTION);
        }

        if (nextInput === EInputs.LOCATION) {
            onOpenLocation(anchorTargetElLocation);
            onChangeInputInFocus(EInputs.LOCATION);
        }

        if (nextInput === EInputs.CATEGORY) {
            onOpenCategory(anchorTargetCategoryEvent);
            onChangeInputInFocus(EInputs.CATEGORY);
        }
    }, [
        onOpenCategory,
        onOpenLocation,
        onOpenTransaction,
        onChangeInputInFocus,
        anchorTargetElLocation,
        anchorTargetElTransaction,
        anchorTargetCategoryEvent,
    ]);

    const onStayFocused = useCallback((nextInput: EInputs) => {
        if (nextInput === EInputs.LOCATION) {
            const locationRefCurrent = locationInputRef?.current;
            if (locationRefCurrent) locationRefCurrent.focus();
        }

    }, [locationInputRef]);

    return {
        onSelectNext,
        onStayFocused,
    };
};
