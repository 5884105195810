import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';

interface IOption {
    location: ILocation;
    onClick: (location: ILocation) => void;
}

export const Option: FC<IOption> = ({
    onClick,
    location,
}) => {

    return <Box
        gap={1}
        display='flex'
        alignItems='center'
        borderRadius='99px'
        padding='10px 12px 10px 16px'
        bgcolor={
            (theme) => theme.palette.identityLightGreen.main
        }
    >
        <Text variant='body3' semibold>
            {location.name}
        </Text>

        <Svg
            icon
            width={16}
            component={CloseIcon}
            sx={{ cursor: 'pointer' }}
            onIconClick={() => onClick(location)}
        />
    </Box>;
};