import { IAdvertisementsListResult } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/IAdvertisementsListResult';

// Poziadavka od Produktu, nezobrazovat DP ktore nemaju ziadne inzeraty kt. by vyhovovali filru vo vysledkoch NSK-34200
export const reduceDevProjectsAccordingToCountMatchesFilter = (
    results: IAdvertisementsListResult[],
    count = 0
): IAdvertisementsListResult[] => {
    const devProjectsWithoutMatchesAdvertisementsFiler = results.filter(
        (result) =>
            result.devProject &&
            result.devProject.advertisementsInfo
                .map((info) => info.countMatchesTheFilter)
                .includes(count)
    );

    const devProjectsWithoutAdvertisements = results.filter(
        (result) =>
            result.devProject && !result.devProject?.advertisementsInfo[0]
    );

    const devProjectIdsToRemove = [
        ...devProjectsWithoutMatchesAdvertisementsFiler,
        ...devProjectsWithoutAdvertisements,
    ].map((project) => project.devProject.id);

    return results.filter(
        (result) =>
            result.advertisement ||
            !devProjectIdsToRemove.includes(result.devProject.id)
    );
};
