import { useEffect } from 'react';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const useArea = (
    areasFromFilter: { from: number, to: number},
): void => {
    const setAreaTo = useExtendedStore((store) => store.setAreaTo);
    const setAreaFrom = useExtendedStore((store) => store.setAreaFrom);

    useEffect(() => {
        if (areasFromFilter.from) {
            setAreaFrom(areasFromFilter.from);
        }

        if (areasFromFilter.to) {
            setAreaTo(areasFromFilter.to);
        }
    }, [
        areasFromFilter.from,
        areasFromFilter.to,
        setAreaFrom,
        setAreaTo,
    ]);
};