import { FC, ReactNode } from 'react';
import { Popper, Box, ClickAwayListener } from '@mui/material';

type OffsetOptionsType = {x: number; y: number};
interface IPopover {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    anchorEl: HTMLElement;
    width?: string | number;
    borderRadius?: string;
    offsetOptions: OffsetOptionsType;
}

export const Popover: FC<IPopover> = ({
    width,
    isOpen,
    onClose,
    children,
    anchorEl,
    borderRadius,
    offsetOptions,
}) => {

    return <Popper
        open={isOpen}
        anchorEl={anchorEl}
        placement='bottom'
        modifiers={[
            {
                name: 'offset',
                options: { offset: [
                    offsetOptions.x,
                    offsetOptions.y
                ]},
            },
        ]}
        sx={{
            width: width,
            zIndex: 10000,
        }}
    >
        <ClickAwayListener onClickAway={onClose}>
            <Box
                width='100%'
                borderRadius={borderRadius ? borderRadius : '20px'}
                bgcolor={(theme) => theme.palette.backgroundPrimary.main}
                sx={{
                    overflow: 'hidden',
                    backdropFilter: 'blur(16px)',
                    boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.16)'
                }}
            >
                {children}
            </Box>
        </ClickAwayListener>
    </Popper>;
};

