import { useMemo } from 'react';
import { ITransaction } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import { ETransactionName } from 'components/advertisementsFilter/modules/transaction/enums/ETransactionName';
import { IOption } from 'modules/theme/components/filter/switchFilter/interfaces/IOption';

interface IReturn {
    selectedOption: IOption;
    selectedOptionTitle: string;
    transactionOptions: IOption[];
}

export const useTransactionOptions = (
    transactions: ITransaction[],
    selectedTransaction: ITransaction
): IReturn => {

    const transactionOptions = useMemo(() => {
        return transactions.map((option) => ({
            value: option.id.toString(),
            name: ETransactionName[option.name],
        }));
    }, [transactions]);

    const selectedOption = useMemo(() => {
        return transactionOptions.find(
            (option) => option.value === selectedTransaction?.id.toString()
        );
    }, [selectedTransaction, transactionOptions]);

    const selectedOptionTitle = useMemo(() => {
        return selectedOption && selectedOption.name;
    }, [selectedOption]);

    return {
        selectedOption,
        transactionOptions,
        selectedOptionTitle,
    };
};