import { FC } from 'react';
import { Box } from '@mui/material';
import {
    IAutocompleteOption
} from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';
import {
    OptionContent
} from 'components/advertisementsFilter/modules/location/components/common/autocompletes/common/optionContent/OptionContent';

interface IOption {
    onClick: () => void;
    withXPadding?: boolean;
    isWithParents?: boolean;
    option: IAutocompleteOption;
}

export const Option: FC<IOption> = ({
    option,
    onClick,
    withXPadding,
}) => {

    return <Box
        gap={2}
        paddingY={1}
        display='flex'
        onClick={onClick}
        alignItems='center'
        paddingX={{ xs: withXPadding ? 4 : 0 }}
        sx={(theme) => ({
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.backgroundSecondary.main
            }
        })}
    >
        <OptionContent
            title={option.name}
            subTitle={option.subName}
        />
    </Box>;
};