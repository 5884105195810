import { AdvertisementListResultAdvertisementParametersCategoryDto } from 'modules/api/generated';
import { subCategoryEnumsFromDtoRecord } from 'modules/api/clients/advertisement/common/constants/records/filter/category/sub/fromDto/subCategoryEnumsFromDtoRecord';
import { mainCategoryEnumsFromDtoRecord } from 'modules/api/clients/advertisement/common/constants/records/filter/category/main/fromDto/mainCategoryEnumsFromDtoRecord';
import { ICategory } from 'modules/api/clients/advertisement/common/interfaces/category/ICategory';

export const mapCategoryDtoToICategory = (
    dto: AdvertisementListResultAdvertisementParametersCategoryDto
): ICategory => ({
    mainValue: mainCategoryEnumsFromDtoRecord[dto.mainValue],
    ...(!!dto.subValue && { subValue: subCategoryEnumsFromDtoRecord[dto.subValue] }),
});
