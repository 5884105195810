import { FC } from 'react';
import { Box, Drawer } from '@mui/material';
import {
    DialogHeader
} from 'components/advertisementsFilter/components/extendedFilter/common/header/DialogHeader';
import {
    DialogFooter
} from 'components/advertisementsFilter/components/extendedFilter/common/footer/DialogFooter';
import {
    DialogContent
} from 'components/advertisementsFilter/components/extendedFilter/common/content/DialogContent';
import { useTotalCountStore } from 'components/advertisementsFilter/features/totalCount/store/useTotalCountStore';
import { useRedirectByFilter } from 'components/advertisementsFilter/features/search/hooks/useRedirectByFilter';
import { useSearchFilterReset } from 'components/advertisementsFilter/features/filterReset/hooks/useSearchFilterReset';

interface IExtendedMobileFilterDialog {
    onCloseFilter: () => void;
    toggleExtendedFilter: (flag: boolean) => void;
    isExtendedFilterOpened: boolean;
}

export const ExtendedMobileFilterDialog: FC<IExtendedMobileFilterDialog> = ({
    onCloseFilter,
    toggleExtendedFilter,
    isExtendedFilterOpened,
}) => {
    const totalCount = useTotalCountStore((store) => store.totalCount);

    const { onRedirectByFilter } = useRedirectByFilter();
    const { onSearchFilterReset } = useSearchFilterReset();

    return <Drawer
        anchor='bottom'
        open={isExtendedFilterOpened}
        sx={{
            '& .MuiBackdrop-root': {
                backgroundColor: 'inherit',
            },
            '& .MuiDrawer-paper': {
                width: '100%',
                borderRadius: 0,
                boxShadow: 'none',
                height: 'calc(100%)'
            }
        }}
    >
        <Box
            height='100%'
            display='flex'
            position='relative'
            alignItems='center'
            flexDirection='column'
        >
            <DialogHeader onCloseFilter={onCloseFilter}/>

            <DialogContent/>

            <DialogFooter
                totalCount={totalCount}
                onRedirectByFilter={() => {
                    toggleExtendedFilter(!isExtendedFilterOpened);
                    onRedirectByFilter();
                }}
                onSearchFilterReset={onSearchFilterReset}
            />
        </Box>
    </Drawer>;
};
