import { AdvertisementsListResultDto } from 'modules/api/generated';
import { IAdvertisementsListResult } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/IAdvertisementsListResult';
import { mapAdvertisementsListResultAdvertisementDtoToIAdvertisementsListResultAdvertisement } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/mapAdvertisementsListResultAdvertisementDtoToIAdvertisementsListResultAdvertisement';
import { mapAdvertisementsListResultDevProjectDtoToIAdvertisementsListResultDevProject } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/devProject/mapAdvertisementsListResultDevProjectDtoToIAdvertisementsListResultDevProject';

export const mapAdvertisementsListResultDtoToIAdvertisementsListResult = (
    dto: AdvertisementsListResultDto
): IAdvertisementsListResult => ({
    ...(!!dto.devProject && {
        devProject:
            mapAdvertisementsListResultDevProjectDtoToIAdvertisementsListResultDevProject(
                dto.devProject
            ),
    }),
    ...(!!dto.advertisement && {
        advertisement:
            mapAdvertisementsListResultAdvertisementDtoToIAdvertisementsListResultAdvertisement(
                dto.advertisement
            ),
    }),
});
