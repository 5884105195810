import {EPriceCurrency} from 'modules/api/clients/advertisement/common/enums/parameters/price/EPriceCurrency';
import {EPriceUnit} from 'modules/api/clients/advertisement/common/enums/parameters/price/EPriceUnit';

export const priceUnitsRecord = (currency: EPriceCurrency): Record<EPriceUnit, string | EPriceCurrency> => ({
    [EPriceUnit.CURRENCY]: currency,
    [EPriceUnit.CURRENCY_PER_M2]: `${currency}/m²`,
    [EPriceUnit.CURRENCY_PER_DAY]: `${currency}/deň`,
    [EPriceUnit.CURRENCY_PER_YEAR]: `${currency}/rok`,
    [EPriceUnit.CURRENCY_PER_MONTH]: `${currency}/mes.`,
    [EPriceUnit.CURRENCY_PER_M2_PER_YEAR]: `${currency}/m²/rok`,
    [EPriceUnit.CURRENCY_PER_M2_PER_MONTH]: `${currency}/m²/mes.`,
});
