import { FC } from 'react';
import { Box } from '@mui/material';
import {
    MainCategories
} from 'components/advertisementsFilter/modules/categories/components/common/completeBox/partials/mainCategories/MainCategories';
import {
    RollableCategories
} from 'components/advertisementsFilter/modules/categories/components/common/completeBox/partials/rollableCategories/RollableCategories';
import { ICategories } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';

interface ICompleteBox {
    options: ICategories;
    selectedCategoryIds: number[];
    onChangeCategory: (category: number) => void;
}

export const CompleteBox: FC<ICompleteBox> = ({
    options,
    onChangeCategory,
    selectedCategoryIds,
}) => {

    return <Box
        display='flex'
        maxWidth={720}
        flexDirection='column'
        padding={{ xs: 0, md: 4 }}
    >
        <MainCategories
            onChangeCategory={onChangeCategory}
            selectedCategoryIds={selectedCategoryIds}
            options={options.main}
        />

        <RollableCategories
            onChangeCategory={onChangeCategory}
            selectedCategoryIds={selectedCategoryIds}
            options={options}
        />
    </Box>;
};