export enum ELabel {
    ADD = 'pridat-inzerat',
    BROKER = 'makler-na-kluc',
    VALUATE = 'ocenenie-nehnutelnosti',
    CALCULATE = 'vypocet-hypoteky',
    LANDCHECK = 'overovanie-pozemkov',
    LISTING = 'zmluvy-a-informacie',
    LOGIN = 'prihlasit-sa',
    CLICK_ON_NAME = 'klik-na-meno',
    SHOW_ON_MAP = 'zobrazit-na-mape',
}