import {AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum} from 'modules/api/generated';
import {EPriceCurrency} from 'modules/api/clients/advertisement/common/enums/parameters/price/EPriceCurrency';

// @TODO doriesit enumy, zatial pouzity enum pre listing
export const priceCurrencyEnumsRecord: Record<AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum, EPriceCurrency> = {
    [AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum.Eur]: EPriceCurrency.EUR,
    [AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum.Czk]: EPriceCurrency.CZK,
    [AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum.Gbp]: EPriceCurrency.GBP,
    [AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum.Usd]: EPriceCurrency.USD,
    [AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum.Pln]: EPriceCurrency.PLN,
    [AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum.Huf]: EPriceCurrency.HUF,
    [AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum.Ron]: EPriceCurrency.RON,
    [AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum.Rsd]: EPriceCurrency.RSD,
};
