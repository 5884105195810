import { AdvertisementDetailAdvertiserDtoTypeEnum } from 'modules/api/generated';
import { EAdvertiserType } from 'modules/api/clients/advertisement/detail/enums/advertiser/EAdvertiserType';

export const advertiserTypeEnumsRecord: Record<
    AdvertisementDetailAdvertiserDtoTypeEnum,
    EAdvertiserType
> = {
    [AdvertisementDetailAdvertiserDtoTypeEnum.Agent]: EAdvertiserType.AGENT,
    [AdvertisementDetailAdvertiserDtoTypeEnum.PrivatePerson]:
        EAdvertiserType.PRIVATE_PERSON,
};
