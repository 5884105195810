export enum ECategoryName {
    REAL_ESTATES = 'Nehnuteľnosti',
    APARTMENTS = 'Byty',
    SECONDARY_HOUSES = 'Domy',
    SECONDARY_LANDS = 'Pozemky',
    SECONDARY_RECREATIONAL_PROPERTIES = 'Rekreačné',
    SECONDARY_SPACES_AND_OBJECTS = 'Komerčné',
    SECONDARY_STUDIO_APARTMENT = 'Garsónka',
    ONE_ROOM_APARTMENT = '1-izbový',
    TWO_ROOM_APARTMENT = '2-izbový',
    SECONDARY_THREE_ROOM_APARTMENT = '3-izbový',
    FOUR_ROOM_APARTMENT = '4-izbový',
    FIVE_PLUS_ROOM_APARTMENT = '5+ izbový',
    HOLIDAY_APARTMENT = 'Apartmán',
    LOFT = 'Loft',
    MAISONETTE = 'Mezonet',
    SECONDARY_LAND_FOR_FAMILY_HOUSES = 'Pozemky pre \nrod. domy',
    SECONDARY_RECREATIONAL_LAND = 'Rekreačný pozemok',
    SECONDARY_COMMERCIAL_PLOTS = 'Komerčné pozemky',
    SECONDARY_GARDEN = 'Záhrada',
    SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS = 'Sady, vinice, chmelnice',
    SECONDARY_AGRICULTURAL_AND_FOREST_LAND = 'Poľnohosp. \na lesné pozemky',
    SECONDARY_GARAGE_AND_PARKING = 'Garáže',
    SECONDARY_OFFICES_AND_ADMINISTRATION = 'Kancelárie \na administratíva',
    SECONDARY_THE_SHOP = 'Obchod',
    SECONDARY_A_RESTAURANT = 'Reštaurácia',
    SECONDARY_WAREHOUSES = 'Sklady',
    SECONDARY_PRODUCTION = 'Výroba',
    SECONDARY_HOTEL_GUESTHOUSE = 'Hotel, penzión',
    SECONDARY_ANOTHER_SPACE_AND_OBJECT = 'Iné priestory \na objekty',
}