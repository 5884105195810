import {AdvertisementDetailAdvertisementCategoryDtoSubValueEnum} from 'modules/api/generated';
import {ESubCategory} from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export const subCategoryEnumsToDtoRecord: Record<ESubCategory, AdvertisementDetailAdvertisementCategoryDtoSubValueEnum> = {
    [ESubCategory.STUDIO_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.StudioApartment,
    [ESubCategory.DOUBLE_STUDIO_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.DoubleStudioApartment,
    [ESubCategory.ONE_ROOM_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OneRoomApartment,
    [ESubCategory.TWO_ROOM_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.TwoRoomApartment,
    [ESubCategory.THREE_ROOM_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ThreeRoomApartment,
    [ESubCategory.FOUR_ROOM_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.FourRoomApartment,
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.FivePlusRoomApartment,
    [ESubCategory.HOLIDAY_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.HolidayApartment,
    [ESubCategory.LOFT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Loft,
    [ESubCategory.MAISONETTE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Maisonette,
    [ESubCategory.OTHER_TYPE_OF_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherTypeOfApartment,
    [ESubCategory.FAMILY_HOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.FamilyHouse,
    [ESubCategory.COUNTRY_HOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CountryHouse,
    [ESubCategory.FARM_SETTLEMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.FarmSettlement,
    [ESubCategory.MOBILE_HOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.MobileHouse,
    [ESubCategory.HOUSEBOAT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Houseboat,
    [ESubCategory.OTHER_OBJECT_FOR_HOUSING]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherObjectForHousing,
    [ESubCategory.CABIN_AND_LOG_CABIN]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CabinAndLogCabin,
    [ESubCategory.COTTAGE_AND_RECREATION_HOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CottageAndRecreationHouse,
    [ESubCategory.GARDEN_HUT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.GardenHut,
    [ESubCategory.OTHER_RECREATIONAL_OBJECT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherRecreationalObject,
    [ESubCategory.LAND_FOR_FAMILY_HOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.LandForFamilyHouse,
    [ESubCategory.RECREATIONAL_LAND]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RecreationalLand,
    [ESubCategory.LAND_FOR_HOUSING_CONSTRUCTION]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.LandForHousingConstruction,
    [ESubCategory.LAND_FOR_CIVIC_AMENITIES]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.LandForCivicAmenities,
    [ESubCategory.COMMERCIAL_ZONE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CommercialZone,
    [ESubCategory.INDUSTRIAL_ZONE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.IndustrialZone,
    [ESubCategory.MIXED_ZONE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.MixedZone,
    [ESubCategory.GARDEN]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Garden,
    [ESubCategory.ORCHARD]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Orchard,
    [ESubCategory.VINEYARD_AND_HOP_GARDEN]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.VineyardAndHopGarden,
    [ESubCategory.MEADOW_AND_PASTURE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.MeadowAndPasture,
    [ESubCategory.ARABLE_LAND]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ArableLand,
    [ESubCategory.FOREST_LAND]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ForestLand,
    [ESubCategory.POND_AND_LAKE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.PondAndLake,
    [ESubCategory.LAND_FOR_ADVERTISING]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.LandForAdvertising,
    [ESubCategory.OTHER_TYPE_OF_LAND]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherTypeOfLand,
    [ESubCategory.OFFICES]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Offices,
    [ESubCategory.BUSINESS_SPACES]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.BusinessSpaces,
    [ESubCategory.RESTAURANT_SPACES]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RestaurantSpaces,
    [ESubCategory.STORAGE_AREAS]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.StorageAreas,
    [ESubCategory.SPACE_FOR_PRODUCTION]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SpaceForProduction,
    [ESubCategory.REPAIR_AREA]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RepairArea,
    [ESubCategory.SPORTS_FACILITIES]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SportsFacilities,
    [ESubCategory.SPACE_FOR_ADVERTISEMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SpaceForAdvertisement,
    [ESubCategory.OTHER_TYPE_OF_SPACE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherTypeOfSpace,
    [ESubCategory.APARTMENT_HOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ApartmentHouse,
    [ESubCategory.RENTAL_HOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RentalHouse,
    [ESubCategory.OFFICE_BUILDING]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OfficeBuilding,
    [ESubCategory.COMMERCIAL_OBJECT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CommercialObject,
    [ESubCategory.RESTAURANT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Restaurant,
    [ESubCategory.POLYFUNCTIONAL_BUILDING]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.PolyfunctionalBuilding,
    [ESubCategory.WAREHOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Warehouse,
    [ESubCategory.MANUFACTURING_FACILITY]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ManufacturingFacility,
    [ESubCategory.REPAIR_FACILITY]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.RepairFacility,
    [ESubCategory.OBJECT_FOR_BREEDING_ANIMALS]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ObjectForBreedingAnimals,
    [ESubCategory.HOTEL_AND_PENSION]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.HotelAndPension,
    [ESubCategory.SPA]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.Spa,
    [ESubCategory.HISTORICAL_OBJECT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.HistoricalObject,
    [ESubCategory.COMMERCIAL_FACILITY]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.CommercialFacility,
    [ESubCategory.SPORT_OBJECT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SportObject,
    [ESubCategory.SMALL_POWER_STATION]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SmallPowerStation,
    [ESubCategory.GAS_STATION]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.GasStation,
    [ESubCategory.MOBILE_CELLS_AND_STANDS]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.MobileCellsAndStands,
    [ESubCategory.OTHER_TYPE_OF_OBJECT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherTypeOfObject,
    [ESubCategory.DETACHED_GARAGE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.DetachedGarage,
    [ESubCategory.CONSOLIDATED_GROUND]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.ConsolidatedGround,
    [ESubCategory.AGRICULTURAL_OBJECT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.AgriculturalObject,
    [ESubCategory.OTHER_LAND_TO_BUILDING]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherLandToBuilding,
    [ESubCategory.OTHER_AGRICULTURAL_LAND]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.OtherAgriculturalLand,
    [ESubCategory.SECONDARY_STUDIO_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryStudioApartment,
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryThreeRoomApartment,
    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryLandForFamilyHouses,
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryRecreationalLand,
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryCommercialPlots,
    [ESubCategory.SECONDARY_GARDEN]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryGarden,
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryOrchardsVineyardsHopFields,
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryAgriculturalAndForestLand,
    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryGarageAndParking,
    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryOfficesAndAdministration,
    [ESubCategory.SECONDARY_THE_SHOP]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryTheShop,
    [ESubCategory.SECONDARY_A_RESTAURANT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryARestaurant,
    [ESubCategory.SECONDARY_WAREHOUSES]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryWarehouses,
    [ESubCategory.SECONDARY_PRODUCTION]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryProduction,
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryAnotherSpaceAndObject,
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum.SecondaryHotelGuesthouse,
};
