import { FC } from 'react';
import {
    CategoryInput
} from 'components/advertisementsFilter/modules/categories/components/common/input/CategoryInput';
import {
    CompleteBox
} from 'components/advertisementsFilter/modules/categories/components/common/completeBox/CompleteBox';
import { Popover } from 'components/advertisementsFilter/modules/common/components/popover/Popover';
import { EInputs } from 'components/advertisementsFilter/modules/common/enums/EInputs';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';

interface ICategory {
    isHovered: boolean;
    isInFocus: boolean;
    isPopoverOpen: boolean;
    onClosePopover: () => void;
    popoverAnchorEl: HTMLElement;
    onChangeHoverState: (flag: EInputs) => void;
    onSetInputInFocus: (value: EInputs) => void;
    onOpenPopover: (target: HTMLElement) => void;
}

export const Category: FC<ICategory> = ({
    isInFocus,
    isHovered,
    onOpenPopover,
    isPopoverOpen,
    onClosePopover,
    popoverAnchorEl,
    onSetInputInFocus,
    onChangeHoverState,
}) => {
    const categoryOptions = useCategoriesStore((store) => store.categoryOptions);
    const onSelectCategory = useCategoriesStore((store) => store.onSelectCategory);
    const onCategoriesReset = useCategoriesStore((store) => store.onCategoriesReset);
    const onDeselectCategory = useCategoriesStore((store) => store.onDeselectCategory);
    const selectedCategoryIds = useCategoriesStore((store) => store.selectedCategoryIds);
    const selectedCategoryNames = useCategoriesStore((store) => store.selectedCategoryNames);

    return <>
        <CategoryInput
            placeholder='Všetko'
            label='Nehnuteľnosť'
            isHovered={isHovered}
            isInFocus={isInFocus}
            clearButtonMargin={14}
            onClick={onOpenPopover}
            value={selectedCategoryNames}
            onSetInputInFocus={onSetInputInFocus}
            onCategoriesReset={onCategoriesReset}
            onChangeHoverState={onChangeHoverState}
        />

        <Popover
            isOpen={isPopoverOpen}
            onClose={() => {
                onClosePopover();
                onSetInputInFocus(null);
            }}
            anchorEl={popoverAnchorEl}
            offsetOptions={{x: -86, y: 23}}
        >
            <CompleteBox
                options={categoryOptions}
                onChangeCategory={(categoryId) => {
                    const isAlreadySelected = selectedCategoryIds.includes(
                        categoryId
                    );

                    return isAlreadySelected
                        ? onDeselectCategory(categoryId)
                        : onSelectCategory(categoryId);
                }}
                selectedCategoryIds={selectedCategoryIds}
            />
        </Popover>
    </>;
};
