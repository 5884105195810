import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import {
    Option
} from 'components/advertisementsFilter/modules/extended/components/location/partials/previouslySearched/partials/option/Option';
import { IAutocompleteOption } from 'components/advertisementsFilter/modules/location/interfaces/IAutocompleteOption';

interface IPreviouslySearched {
    title: string;
    paddingTop?: number;
    options: {
        locationOptions: IAutocompleteOption[];
    }[];
    onClick: (locations: IAutocompleteOption[]) => void
}

export const PreviouslySearched: FC<IPreviouslySearched> = ({
    title,
    onClick,
    options,
    paddingTop,
}) => {

    return <Box paddingTop={paddingTop}>
        {title &&
            <Box
                paddingX={1}
                paddingTop={1}
                paddingBottom={0.5}
            >
                <Text
                    semibold
                    variant='label1'
                    color={(palette) => palette.labelSecondary}
                >
                    {title}
                </Text>
            </Box>
        }

        <Box
            display='flex'
            flexDirection='column'
            overflow={{ xs: 'auto', md: 'visible' }}
        >
            {options.map((option, index) => <Option
                key={index}
                locationOptions={option.locationOptions}
                onClick={() => {
                    onClick(option.locationOptions);
                }}
            />)}
        </Box>
    </Box>;
};