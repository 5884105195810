import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    SelectLocationsHint
} from 'components/advertisementsFilter/modules/location/components/common/completeBox/partials/selectHint/SelectLocationsHint';

interface ICompleteBox {
    options: ILocation[];
    withXPadding?: boolean;
    onChangeLocation: (location: ILocation) => void;
}

export const CompleteBox: FC<ICompleteBox> = () => {

    return <Box
        pt={{xs: 0, md: 1 }}
        px={{ xs: 0, md: 4 }}
    >
        <Box mb={1}>
            <Text
                semibold
                variant='label1'
                color={(palette) => palette.labelSecondary}
            >
                Zadajte mesto, obec, okres ...
            </Text>
        </Box>
        <SelectLocationsHint/>
    </Box>;
};
