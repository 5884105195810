import { FC } from 'react';
import { Grid2 } from '@mui/material';
import { IGridCategory } from 'components/advertisementsFilter/modules/categories/interfaces/IGridCategory';
import {
    CategoryGridItem
} from 'components/advertisementsFilter/modules/categories/components/common/completeBox/partials/rollableCategories/partials/gridItems/partials/item/Item';

interface ICategoryGridItems {
    items: IGridCategory[];
    onChangeCategory: (id: number) => void;
}

export const CategoryGridItems: FC<ICategoryGridItems> = ({
    items,
    onChangeCategory
}) => {

    return <Grid2 container direction='column' spacing={2} mt={2}>
        {items.map((gridItem) =>
            <CategoryGridItem
                item={gridItem}
                key={gridItem.id}
                onChange={onChangeCategory}
            />
        )}
    </Grid2>;
};