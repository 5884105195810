import {
    IBreadcrumbCommonParameter
} from 'modules/api/clients/advertisement/common/interfaces/breadcrumb/partials/part/partials/commonParameter/IBreadcrumbCategory';
import { AdvertisementBreadcrumbItemTransactionDto } from 'modules/api/generated';

export const mapAdvertisementBreadcrumbItemTransactionDtoToIBreadcrumbCommonParameter = (advertisementBreadcrumbItemCategory: AdvertisementBreadcrumbItemTransactionDto): IBreadcrumbCommonParameter => {
    return {
        id: advertisementBreadcrumbItemCategory.id,
        name: advertisementBreadcrumbItemCategory.name,
        sefName: advertisementBreadcrumbItemCategory.sefName,
    };
};