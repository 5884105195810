import {EPriceUnit} from 'modules/api/clients/advertisement/common/enums/parameters/price/EPriceUnit';
import {EPriceCurrency} from 'modules/api/clients/advertisement/common/enums/parameters/price/EPriceCurrency';
import {
    priceUnitsRecord
} from 'modules/api/clients/advertisement/common/constants/records/parameters/price/priceUnitsRecord';

export const formatPrice = (
    priceValue: number,
    currency: EPriceCurrency = EPriceCurrency.EUR,
    priceUnit: EPriceUnit = EPriceUnit.CURRENCY
): string => {

    const formatter = new Intl.NumberFormat('sk-SK', {maximumFractionDigits: 2,});

    return `${formatter.format(priceValue)} ${priceUnitsRecord(currency)[priceUnit]}`;
};
