import { create } from 'zustand';
import {
    ICategory
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import { ICategories } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import { apiCategoryToECategoryNameRecord } from 'components/advertisementsFilter/modules/categories/records/apiCategoryToECategoryNameRecord';

type CategoriesStore = {
    categoryOptions: ICategories;
    selectedCategoryIds: number[];
    selectedCategoryNames: string;
    onCategoriesReset: () => void;
    selectedCategories: ICategory[];
    setSelectedCategoryIds: () => void;
    setSelectedCategoryNames: () => void;
    onSelectCategory: (categoryId: number) => void;
    onDeselectCategory: (catrgoryId: number) => void;
    setCategoryOptions: (options: ICategories) => void;
    setSelectedCategories: (categories: ICategory[]) => void;
};

const excludeOrIncludeParentCategory = (
    categoryToSelect: ICategory,
    selectedCategories: ICategory[],
): ICategory[] => {
    let categoriesToSelect = selectedCategories;

    if (categoryToSelect.isMain) {
        const areChildrenSubcategoriesSelected = selectedCategories
            .map((selectedCategory) => {
                const isSelected =
                    selectedCategory.parentId === categoryToSelect.id;

                if (isSelected) return selectedCategory;
            });

        if (areChildrenSubcategoriesSelected.length) {
            categoriesToSelect = selectedCategories.filter(
                (selectedCategory) => selectedCategory.parentId !== categoryToSelect.id,
            );
        }
    } else {
        categoriesToSelect = selectedCategories.filter(
            (selectedCategory) => selectedCategory.id !== categoryToSelect.parentId,
        );
    }

    return categoriesToSelect;
};

export const useCategoriesStore = create<CategoriesStore>((set) => ({
    categoryOptions: {
        main: [],
        sub: [],
    },
    selectedCategories: [],
    selectedCategoryIds: [],
    selectedCategoryNames: '',

    setCategoryOptions: (options: ICategories) => set(() => ({
        categoryOptions: options,
    })),

    setSelectedCategories: (categories: ICategory[]) => set(() => {
        return {
            selectedCategories: categories,
        };
    }),

    onSelectCategory: (categoryId: number) => set((store) => {
        const categoriesAll = [
            ...store.categoryOptions.main,
            ...store.categoryOptions.sub,
        ];

        const categoryToSelect = categoriesAll.find(
            (category) => category.id === categoryId,
        );

        if (categoryToSelect) {
            return {
                selectedCategories: [
                    ...excludeOrIncludeParentCategory(
                        categoryToSelect,
                        store.selectedCategories,
                    ),
                    categoryToSelect,
                ],
            };
        }
    }),

    onDeselectCategory: (categoryId: number) => set((store) => {
        const updatedCategories = store.selectedCategories.filter(
            (selectedCategory) => selectedCategory.id !== categoryId,
        );

        return {
            selectedCategories: updatedCategories,
        };
    }),

    setSelectedCategoryIds: () => set((store) => {
        const ids: number[] = [];

        const categoriesAll = [
            ...store.categoryOptions.main,
            ...store.categoryOptions.sub,
        ];

        store.selectedCategories.forEach((selectedCategory) => {
            const parentCategory = categoriesAll.find(
                (category) => category.id === selectedCategory.parentId);

            if (parentCategory && !ids.includes(parentCategory.id)) {
                ids.push(parentCategory.id);
            }

            ids.push(selectedCategory.id);
        });

        return { selectedCategoryIds: ids };

    }),

    setSelectedCategoryNames: () => set((store) => {
        const names = store.selectedCategories
            .map((selectedCategory) => apiCategoryToECategoryNameRecord[selectedCategory.name])
            .join(', ');

        return {
            selectedCategoryNames: names,
        };
    }),

    onCategoriesReset: () => set(() => ({
        selectedCategories: [],
        selectedCategoryIds: [],
        selectedCategoryNames: '',
    }))
}));