import { AdvertisementsListResultAdvertisementDto } from 'modules/api/generated';
import { mapPriceInfoDtoToIPriceInfo } from 'modules/api/clients/advertisement/common/helpers/mappers/price/mapPriceInfoDtoToIPriceInfo';
import { mapFlagsDtoToIFlags } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/flags/mapFlagsDtoToIFlags';
import { MapMediaDtoToIMedia } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/media/mapMediaDtoToIMedia';
import { mapPhotosDtoToIPhoto } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/photos/mapPhotosDtoToIPhoto';
import { mapParametersDtoToIParameter } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/common/parameters/mapParametersDtoToIParameter';
import { mapAdvertiserDtoToIAdvertiser } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/common/advertiser/mapAdvertiserDtoToIAdvertiser';
import { mapDevProjectDtoToIDevProject } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/devProject/mapDevProjectDtoToIDevProject';
import { IAdvertisementsListResultAdvertisement } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/IAdvertisementsListResultAdvertisement';
import { mapSearchResultsLinkDtoToISearchResultsLink } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/searchResultsLink/mapSearchResultsLinkDtoToISearchResultsLink';
import { mapAdvertisementListResultLocationToILocation } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/advertisement/advertisementListResult/location/mapAdvertisementListResultLocationToILocation';

export const mapAdvertisementsListResultAdvertisementDtoToIAdvertisementsListResultAdvertisement =
    (
        dto: AdvertisementsListResultAdvertisementDto
    ): IAdvertisementsListResultAdvertisement => ({
        id: dto.id,
        title: dto.title,
        sefName: dto.sefName,
        description: dto.description,
        createdAt: dto?.createdAt || null,
        updatedAt: dto?.updatedAt || null,
        location: mapAdvertisementListResultLocationToILocation(dto.location),
        flags: mapFlagsDtoToIFlags(dto.flags),
        media: MapMediaDtoToIMedia(dto.media),
        photos: dto.photos.map(mapPhotosDtoToIPhoto),
        price: mapPriceInfoDtoToIPriceInfo(dto.price),
        parameters: mapParametersDtoToIParameter(dto.parameters),
        advertiser: mapAdvertiserDtoToIAdvertiser(dto.advertiser),
        searchResultsLink: mapSearchResultsLinkDtoToISearchResultsLink(
            dto.searchResultsLink
        ),

        ...(!!dto.devProject && {
            devProject: mapDevProjectDtoToIDevProject(dto.devProject),
        }),
    });
