import { MutableRefObject } from 'react';
import { create } from 'zustand';

type LocationInputStore = {
    locationInputRef: MutableRefObject<HTMLInputElement>;
    setLocationInputRef: (ref: MutableRefObject<HTMLInputElement>) => void;
};

export const useLocationInputStore = create<LocationInputStore>((set) => ({
    locationInputRef: null,
    setLocationInputRef: (ref: MutableRefObject<HTMLInputElement>) => set(() => {
        return { locationInputRef: ref };
    }),
}));