import {
    useSearchFilterCategories
} from 'components/advertisementsFilter/modules/categories/hooks/useCategories';
import {
    useSearchFilterTransaction
} from 'components/advertisementsFilter/modules/transaction/hooks/useTransaction';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useSearchFilterLocation } from 'components/advertisementsFilter/modules/location/hooks/useLocation';
import { useSearchFilterExtended } from 'components/advertisementsFilter/modules/extended/hooks/useExtended';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';

export const useSearchFilter = (
    searchFilterData: IInitialSearchFilterData,
    filterDataFromUrl?: FilterDataFromUrlType,
): void => {
    useSearchFilterTransaction(
        searchFilterData.transactions,
        filterDataFromUrl?.transaction,
    );

    useSearchFilterCategories(
        searchFilterData.categories,
        filterDataFromUrl?.categories,
    );

    useSearchFilterLocation(
        searchFilterData.locations,
        filterDataFromUrl?.locations,
    );

    useSearchFilterExtended(
        filterDataFromUrl,
    );
};