import { useEffect } from 'react';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const useAdvertisements = (
    isOnlyWithVideoFromFilter: boolean,
    isOnlyNewBuildingFromFilter: boolean,
): void => {
    const setIsOnlyNewBuilding = useExtendedStore((store) => store.setIsOnlyNewBuilding);
    const setIsOnlyWithVideo = useExtendedStore((store) => store.setIsOnlyWithVideo);

    useEffect(() => {
        if (isOnlyWithVideoFromFilter) {
            setIsOnlyWithVideo(isOnlyWithVideoFromFilter);
        }

        if (isOnlyNewBuildingFromFilter) {
            setIsOnlyNewBuilding(isOnlyNewBuildingFromFilter);
        }
    }, [
        setIsOnlyWithVideo,
        setIsOnlyNewBuilding,
        isOnlyWithVideoFromFilter,
        isOnlyNewBuildingFromFilter
    ]);
};