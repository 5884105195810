import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { AdvertisementListing } from 'modules/api/clients/advertisement/listing/AdvertisementsListing';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';

type ListingRedirectUrlProps = {
    order: EOrder;
    locations: string[];
    categories: (EMainCategory | ESubCategory)[];
    transaction?: ETransaction;
    fulltext?: string;
    priceFrom?: number;
    priceTo?: number;
    areaFrom?: number;
    areaTo?: number;
    onlyNewbuilding?: boolean;
    onlyWithVideo?: boolean;
};

type Response = {
    title: string;
    url: string;
};

export const getListingRedirectUrl = async ({
    order,
    transaction,
    locations,
    categories,
    fulltext,
    priceFrom,
    priceTo,
    areaFrom,
    areaTo,
    onlyNewbuilding,
    onlyWithVideo,
}: ListingRedirectUrlProps): Promise<Response> => {
    try {
        return await new AdvertisementListing()
            .getAdvertisementsListingPageInternalUrl({
                order,
                transaction,
                locations,
                categories,
                fulltext,
                priceFrom,
                priceTo,
                areaFrom,
                areaTo,
                onlyNewbuilding,
                onlyWithVideo,
            });
    } catch (error) {
        throw new Error(
            `Failed to get listing redirect url. Error: ${error.message}`
        );
    }
};