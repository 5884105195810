import { useEffect } from 'react';
import {
    ICategories
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategories';
import {
    ICategory
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/category/ICategory';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';

export const useSearchFilterCategories = (
    initialCategories: ICategories,
    categoriesFromUrl?: ICategory[],
): void => {
    const setCategoryOptions = useCategoriesStore((store) => store.setCategoryOptions);

    const selectedCategories = useCategoriesStore((store) => store.selectedCategories);
    const setSelectedCategories = useCategoriesStore((store) => store.setSelectedCategories);
    const setSelectedCategoryIds = useCategoriesStore((store) => store.setSelectedCategoryIds);
    const setSelectedCategoryNames = useCategoriesStore((store) => store.setSelectedCategoryNames);

    useEffect(() => {
        setCategoryOptions({
            main: initialCategories.main,
            sub: initialCategories.sub,
        });
    }, [initialCategories.main, initialCategories.sub, setCategoryOptions]);

    useEffect(() => {
        if (!categoriesFromUrl || !categoriesFromUrl.length) return;

        setSelectedCategories(categoriesFromUrl);
    }, [categoriesFromUrl, setSelectedCategories]);

    useEffect(() => {
        setSelectedCategoryIds();
    }, [selectedCategories, setSelectedCategoryIds]);

    useEffect(() => {
        setSelectedCategoryNames();
    }, [
        selectedCategories,
        setSelectedCategoryNames,
    ]);
};