import { FC } from 'react';
import { Box, Grid2 } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { CheckboxWithLabel } from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';
import { IGridCategory } from 'components/advertisementsFilter/modules/categories/interfaces/IGridCategory';

interface ICategoryGridItem {
    item: IGridCategory;
    onChange: (value: number) => void;
}

export const CategoryGridItem: FC<ICategoryGridItem> = ({ item, onChange }) => {

    return (
        <Grid2 size={{xs: 12}}>
            <Box mb={{xs: 1, md: 2}}>
                <Text variant="label1" semibold>
                    {item.name}:
                </Text>
            </Box>

            <Grid2 container spacing={{xs: 0, md: 1}}>
                {item.children.map((categoryOption) => (
                    <Grid2 size={{xs: 6, sm: 4, md: 'auto'}} key={categoryOption.id}>
                        <CheckboxWithLabel
                            onClick={() => onChange(categoryOption.id)}
                            isChecked={categoryOption.isSelected}
                            label={categoryOption.name}
                        />
                    </Grid2>
                ))}
            </Grid2>
        </Grid2>
    );
};
