import { FC } from 'react';
import { Box } from '@mui/material';
import { SearchButton } from 'components/advertisementsFilter/components/common/searchButton/SearchButton';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';
import { useRedirectByFilter } from 'components/advertisementsFilter/features/search/hooks/useRedirectByFilter';
import {
    ExtendedFilterButton
} from 'components/advertisementsFilter/components/desktop/partials/buttons/partials/extendedFilterButton/ExtendedFilterButton';

interface IButtons {
    isExtendedDialogOpened: boolean;
    onExtendedDialogOpen: () => void;
}

export const Buttons: FC<IButtons> = ({
    onExtendedDialogOpen,
    isExtendedDialogOpened,
}) => {
    const isActive = useExtendedStore((store) => store.isActive);
    const { onRedirectByFilter } = useRedirectByFilter();

    return <Box
        gap={1}
        display='flex'
        paddingRight={2}
        alignItems='center'
    >
        <ExtendedFilterButton
            isActive={isActive}
            onClick={onExtendedDialogOpen}
            isOpened={isExtendedDialogOpened}
        />

        <SearchButton onClick={onRedirectByFilter}/>
    </Box>;
};
