import { useEffect } from 'react';
import {
    ITransaction
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/transaction/ITransaction';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';

export const useSearchFilterTransaction = (
    initialTransactions: ITransaction[],
    transactionFromUrl?: ITransaction,
): void => {
    const setTransactionOptions = useTransactionStore((store) => store.setTransactionOptions);
    const setSelectedTransaction = useTransactionStore((store) => store.setSelectedTransaction);

    useEffect(() => {
        setTransactionOptions(initialTransactions);
    }, [initialTransactions, setTransactionOptions]);

    useEffect(() => {
        if (!transactionFromUrl) return;

        const transationToSet = initialTransactions.find(
            (transaction) => transaction.name === transactionFromUrl.name);

        if (transationToSet) {
            setSelectedTransaction(transationToSet.id);
        }
    }, [transactionFromUrl, initialTransactions, setSelectedTransaction]);
};
