import { AdvertisementsListResultDevProjectAdvertisementsInfoDto } from 'modules/api/generated';
import { IAdvertisementsListResultDevProjectAdvertisementsInfo } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/devProject/partials/advertisementsInfo/IAdvertisementsListResultDevProjectAdvertisementsInfo';
import { mainCategoryEnumsFromDtoRecord } from 'modules/api/clients/advertisement/common/constants/records/filter/category/main/fromDto/mainCategoryEnumsFromDtoRecord';

export const mapAdvertisementsInfoDtoToIAdvertisementsInfo = (
    dto: AdvertisementsListResultDevProjectAdvertisementsInfoDto
): IAdvertisementsListResultDevProjectAdvertisementsInfo => ({
    totalCount: dto.totalCount,
    category: mainCategoryEnumsFromDtoRecord[dto.category],
    countMatchesTheFilter: dto.countMatchesTheFilter,
});
