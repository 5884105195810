import { AdvertisementsListingFilterParametersDto } from 'modules/api/generated';
import {
    IAdvertisementListingFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/data/IAdvertisementListingFilterData';
import {
    transactionEnumsFromDtoRecord
} from 'modules/api/clients/advertisement/common/constants/records/filter/transaction/fromDto/transactionEnumsRecord';
import {
    mainCategoryEnumsFromDtoRecord
} from 'modules/api/clients/advertisement/common/constants/records/filter/category/main/fromDto/mainCategoryEnumsFromDtoRecord';
import {
    subCategoryEnumsFromDtoRecord
} from 'modules/api/clients/advertisement/common/constants/records/filter/category/sub/fromDto/subCategoryEnumsFromDtoRecord';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';

export const mapAdvertisementListingFilterDataDtoToIAdvertisementListingFilterData = (
    dto: AdvertisementsListingFilterParametersDto
): IAdvertisementListingFilterData => ({
    ...(!!dto.areaTo && ({areaTo: dto.areaTo})),
    ...(!!dto.priceTo && ({priceTo: dto.priceTo})),
    ...(!!dto.fulltext && ({fulltext: dto.fulltext})),
    ...(!!dto.areaFrom && ({areaFrom: dto.areaFrom})),
    ...(!!dto.priceFrom && ({priceFrom: dto.priceFrom})),
    ...(!!dto.locations.length && ({locations: dto.locations})),
    ...(!!dto.onlyWithVideo && ({onlyWithVideo: dto.onlyWithVideo})),
    ...(!!dto.onlyNewbuilding && ({onlyNewbuilding: dto.onlyNewbuilding})),
    ...(!!dto.order && ({order: EOrder[dto.order]})),
    ...(!!dto.transaction && ({transaction: transactionEnumsFromDtoRecord[dto.transaction]})),
    ...(!!dto.categories && ({categories: dto.categories.map((dtoCategory) =>
        mainCategoryEnumsFromDtoRecord[dtoCategory] || subCategoryEnumsFromDtoRecord[dtoCategory])
    })),
});
