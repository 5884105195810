import { Box } from '@mui/material';
import { FC } from 'react';

interface IVerticalDivider {
    isHidden: boolean;
}

export const VerticalDivider: FC<IVerticalDivider> = ({ isHidden }) => {

    return <Box
        height={40}
        width={'1px'}
        visibility={isHidden ? 'hidden' : 'visible'}
        bgcolor={(theme) => theme.palette.dividerTransparent}
    />;
};