import { FC, useCallback } from 'react';
import { Box } from '@mui/material';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { MainCategories } from 'components/advertisementsFilter/modules/categories/components/common/completeBox/partials/mainCategories/MainCategories';
import {
    RollableCategories
} from 'components/advertisementsFilter/modules/categories/components/common/completeBox/partials/rollableCategories/RollableCategories';

export const Categories: FC = () => {
    const options = useCategoriesStore((store) => store.categoryOptions);
    const onSelectCategory = useCategoriesStore((store) => store.onSelectCategory);
    const onDeselectCategory = useCategoriesStore((store) => store.onDeselectCategory);
    const selectedCategoryIds = useCategoriesStore((store) => store.selectedCategoryIds);

    const onChangeCategory = useCallback((categoryId: number) => {
        const isAlreadySelected = selectedCategoryIds.includes(
            categoryId
        );

        return isAlreadySelected
            ? onDeselectCategory(categoryId)
            : onSelectCategory(categoryId);
    }, [selectedCategoryIds, onSelectCategory, onDeselectCategory]);

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Nehnuteľnosť'/>
        </Box>

        <MainCategories
            isExtendedFilter
            options={options.main}
            onChangeCategory={onChangeCategory}
            selectedCategoryIds={selectedCategoryIds}
        />

        <RollableCategories
            options={options}
            onChangeCategory={onChangeCategory}
            selectedCategoryIds={selectedCategoryIds}
        />
    </ContentItemWrapper>;
};