import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { Svg } from 'modules/theme/components/svg/Svg';
import ResetCircleIcon from 'modules/theme/components/icons/basic/ResetCircle.svg';
import SearchIcon from 'modules/theme/components/icons/basic/Search.svg';
import { Input } from 'modules/theme/components/fields/input/Input';
import {
    Title
} from 'components/advertisementsFilter/modules/extended/components/title/Title';
import {
    ContentItemWrapper
} from 'components/advertisementsFilter/modules/extended/components/common/contentItem/wrapper/ContentItemWrapper';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const Fulltext: FC = () => {
    const fulltext = useExtendedStore((store) => store.fulltext);
    const setFulltext = useExtendedStore((store) => store.setFulltext);

    const [localFulltext, setLocalFulltext] = useState(fulltext);

    const onChangeFulltextDebounced = useDebouncedCallback(setFulltext, 500);

    useEffect(() => {
        if (!fulltext) {
            setLocalFulltext('');
        }
    }, [fulltext]);

    const onChangeFulltext = (value: string): void => {
        setLocalFulltext(value);
        onChangeFulltextDebounced(value);
    };

    return <ContentItemWrapper>
        <Box mb={1}>
            <Title text='Kľúčové slovo'/>
        </Box>

        <Box
            mt={{ md: 1 }}
            sx={(theme) => ({
                '& .MuiInputBase-root': { height: 56 },
                '& .MuiFormControl-root .MuiOutlinedInput-root': {
                    height: 56,
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${theme.palette.labelPrimary.main}!important`,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: `2px solid ${theme.palette.labelPrimary.main}!important`,
                    },
                }
            })}
        >
            <Input
                value={localFulltext}
                startIcon={() => <Svg
                    icon
                    width={24}
                    component={SearchIcon}
                />}
                endIcon={localFulltext && (() => <Svg
                    icon
                    width={24}
                    component={ResetCircleIcon}
                    onIconClick={() => {
                        onChangeFulltext('');
                    }}
                />)}
                endIconStopPropagation
                onChange={onChangeFulltext}
                placeholder='Text v inzeráte, názov projektu ...'
            />
        </Box>
    </ContentItemWrapper>;
};