import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export enum SubCategoriesUsed {
    SECONDARY_STUDIO_APARTMENT = ESubCategory.SECONDARY_STUDIO_APARTMENT,
    ONE_ROOM_APARTMENT = ESubCategory.ONE_ROOM_APARTMENT,
    TWO_ROOM_APARTMENT = ESubCategory.TWO_ROOM_APARTMENT,
    SECONDARY_THREE_ROOM_APARTMENT = ESubCategory.SECONDARY_THREE_ROOM_APARTMENT,
    FOUR_ROOM_APARTMENT = ESubCategory.FOUR_ROOM_APARTMENT,
    FIVE_PLUS_ROOM_APARTMENT = ESubCategory.FIVE_PLUS_ROOM_APARTMENT,
    MAISONETTE = ESubCategory.MAISONETTE,
    HOLIDAY_APARTMENT = ESubCategory.HOLIDAY_APARTMENT,
    LOFT = ESubCategory.LOFT,
    SECONDARY_LAND_FOR_FAMILY_HOUSES = ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES,
    SECONDARY_RECREATIONAL_LAND = ESubCategory.SECONDARY_RECREATIONAL_LAND,
    SECONDARY_COMMERCIAL_PLOTS = ESubCategory.SECONDARY_COMMERCIAL_PLOTS,
    SECONDARY_GARDEN = ESubCategory.SECONDARY_GARDEN,
    SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS = ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
    SECONDARY_AGRICULTURAL_AND_FOREST_LAND = ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
    SECONDARY_GARAGE_AND_PARKING = ESubCategory.SECONDARY_GARAGE_AND_PARKING,
    SECONDARY_OFFICES_AND_ADMINISTRATION = ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION,
    SECONDARY_THE_SHOP =  ESubCategory.SECONDARY_THE_SHOP,
    SECONDARY_A_RESTAURANT = ESubCategory.SECONDARY_A_RESTAURANT,
    SECONDARY_WAREHOUSES = ESubCategory.SECONDARY_WAREHOUSES,
    SECONDARY_PRODUCTION = ESubCategory.SECONDARY_PRODUCTION,
    SECONDARY_ANOTHER_SPACE_AND_OBJECT = ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
    SECONDARY_HOTEL_GUESTHOUSE = ESubCategory.SECONDARY_HOTEL_GUESTHOUSE
}