import { AdvertisementsListDto } from 'modules/api/generated';
import { IAdvertisementsList } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/IAdvertisementsList';
import { mapAdvertisementsListResultDtoToIAdvertisementsListResult } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/results/mapAdvertisementsListResultDtoToIAdvertisementsListResult';
import { mapBreadcrumbDtoToIBreadcrumb } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/breadcrumb/mapBreadcrumbDtoToIBreadcrumb';
import { mapMetaDtoToIAdvertisementsListMeta } from 'modules/api/clients/advertisement/listing/helpers/mappers/advertisementsList/meta/mapMetaDtoToIAdvertisementsListMeta';

export const mapAdvertisementsListDtoToIAdvertisementsList = (
    dto: AdvertisementsListDto
): IAdvertisementsList => ({
    title: dto.seo.title,
    totalCount: dto.totalCount,
    meta: mapMetaDtoToIAdvertisementsListMeta(dto.seo.meta),
    breadcrumb: mapBreadcrumbDtoToIBreadcrumb(dto.seo.breadcrumb.parts),
    quickLinks: mapBreadcrumbDtoToIBreadcrumb(dto.seo.quickLinks.parts),
    results: dto.results.map(
        mapAdvertisementsListResultDtoToIAdvertisementsListResult
    ),
});
