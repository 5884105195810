import { AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDto } from 'modules/api/generated';
import { IAdvertisementInfo } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/partials/advertisementsInfo/IAdvertisementInfo';
import { mainCategoryEnumsFromDtoRecord } from 'modules/api/clients/advertisement/common/constants/records/filter/category/main/fromDto/mainCategoryEnumsFromDtoRecord';

export const mapAdvertisementsInfoDtoToIAdvertisementsInfo = (
    advertisementsInfo: AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDto[]
): IAdvertisementInfo => ({
    totalCount: advertisementsInfo.reduce(
        (accumulator, currentValue) => accumulator + currentValue.totalCount,
        0
    ),
    categories: advertisementsInfo.map(
        (dtoCategory) => mainCategoryEnumsFromDtoRecord[dtoCategory.category]
    ),
});
