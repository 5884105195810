import {AdvertisementDetailAdvertisementCategoryDtoMainValueEnum} from 'modules/api/generated';
import {EMainCategory} from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const mainCategoryEnumsToDtoRecord: Record<EMainCategory, AdvertisementDetailAdvertisementCategoryDtoMainValueEnum> = {
    [EMainCategory.LANDS]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Lands,
    [EMainCategory.HOUSES]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Houses,
    [EMainCategory.SPACES]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Spaces,
    [EMainCategory.OBJECTS]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Objects,
    [EMainCategory.APARTMENTS]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.Apartments,
    [EMainCategory.REAL_ESTATES]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.RealEstates,
    [EMainCategory.COTTAGES_AND_CABINS]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.CottagesAndCabins,

    [EMainCategory.SECONDARY_LANDS]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.SecondaryLands,
    [EMainCategory.SECONDARY_HOUSES]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.SecondaryHouses,
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.SecondarySpacesAndObjects,
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum.SecondaryRecreationalProperties,
};