import { useEffect } from 'react';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useSearchStore } from 'components/advertisementsFilter/features/search/store/useSearchStore';
import { useRedirectByFilter } from 'components/advertisementsFilter/features/search/hooks/useRedirectByFilter';
import { useSyncFilterWithStore } from 'components/advertisementsFilter/features/search/hooks/useSyncFilterWithStore';
import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';

interface IReturn {
    onRedirectByFilter: () => void;
}

export const useSearch = (
    initialSearchFilterData: IInitialSearchFilterData,
    pagination?: IAdvertisementListingFilterPagination,
    filterDataFromUrl?: FilterDataFromUrlType,
): IReturn => {
    const setCurrentOrder = useSearchStore((store) => store.setCurrentOrder);
    const { syncFilterWithStore } = useSyncFilterWithStore();

    useEffect(() => {
        syncFilterWithStore(filterDataFromUrl);
    }, [
        filterDataFromUrl,
        syncFilterWithStore,
        initialSearchFilterData,
    ]);

    useEffect(() => {
        if (filterDataFromUrl && filterDataFromUrl.order) {
            setCurrentOrder(filterDataFromUrl.order);
        }
    }, [filterDataFromUrl, pagination, setCurrentOrder]);

    const { onRedirectByFilter } = useRedirectByFilter();

    return {
        onRedirectByFilter,
    };
};