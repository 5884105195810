import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import SearchIcon from 'modules/theme/components/icons/basic/Search.svg';
import { Button } from 'modules/theme/components/button/Button';

interface ISearchButton {
    small?: boolean;
    onClick?: () => void;
    isMobile?: boolean;
}

export const SearchButton: FC<ISearchButton> = ({
    small,
    onClick,
    isMobile,
}) => {

    return <Box sx={{
        zIndex: 10,
        '& .MuiButton-rounded': {
            minWidth: small ? 40 : 48,
            width: small && 40,
            height: small && 40,
            padding: `${14}px!important`
        }
    }}>
        <Button
            rounded
            small={!isMobile}
            onClick={onClick}
            ariaLabel={'search-button'}
            text={(
                <Box
                    gap={1}
                    display='flex'
                    alignItems='center'
                    position='relative'
                >
                    <Svg
                        icon
                        width={20}
                        component={SearchIcon}
                    />

                    {!isMobile &&
                        <Box>
                            <Text
                                semibold
                                variant='label1'
                                color={(pallete) => pallete.labelPrimaryInverse}
                            >
                                Hľadať
                            </Text>
                        </Box>
                    }
                </Box>
            )}
        />
    </Box>;
};