import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ECategoriesLabel } from 'modules/theme/components/listing/cards/promoDevProjectCard/enums/category/ECategoriesLabel';

export const ECategoryLabelRecord: Record<EMainCategory, ECategoriesLabel> = {
    [EMainCategory.APARTMENTS]: ECategoriesLabel.APARTMENTS,
    [EMainCategory.COTTAGES_AND_CABINS]: ECategoriesLabel.COTTAGES_AND_CABINS,
    [EMainCategory.HOUSES]: ECategoriesLabel.HOUSES,
    [EMainCategory.OBJECTS]: ECategoriesLabel.OBJECTS,
    [EMainCategory.SPACES]: ECategoriesLabel.SPACES,
    [EMainCategory.REAL_ESTATES]: ECategoriesLabel.APARTMENTS,
    [EMainCategory.LANDS]: ECategoriesLabel.APARTMENTS,
    [EMainCategory.SECONDARY_HOUSES]: ECategoriesLabel.HOUSES,
    [EMainCategory.SECONDARY_LANDS]: ECategoriesLabel.LANDS,
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]: ECategoriesLabel.SECONDARY_RECREATIONAL_PROPERTIES,
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]: ECategoriesLabel.SECONDARY_SPACES_AND_OBJECTS,
};