import { useMemo } from 'react';

interface IReturn {
    topText: string;
    bottomText: string;
    isAllSelected: boolean;
    isNothingSelected: boolean;
}

export const useMultiInputText = (
    location?: string,
    category?: string,
    transaction?: string,
): IReturn => {

    const data = useMemo(() => {
        const isNothingSelected = !location && !category && !transaction;
        const isAllSelected = !!location && !!category && !!transaction;

        const isOnlyLocation = location && !category && !transaction;
        const isOnlyCategory = !location && category && !transaction;
        const isOnlyTransaction = !location && !category && transaction;
        const areLocationAndCategory = location && category && !transaction;
        const areLocationAndTransaction = location && !category && transaction;
        const areCategoryAndTransaction = !location && category && transaction;

        let topText = 'Lokalita';
        let bottomText = 'Mesto, obec ...';

        if (
            isAllSelected ||
            areLocationAndCategory ||
            areLocationAndTransaction
        ) {
            topText = location;
        }

        if (
            isAllSelected ||
            isOnlyTransaction ||
            areLocationAndTransaction
        ) {
            bottomText = transaction;
        }

        if (
            isOnlyCategory ||
            areLocationAndCategory ||
            areCategoryAndTransaction
        ) {
            bottomText = category;
        }

        if (isAllSelected) topText = location;
        if (isOnlyLocation) bottomText = location;
        if (isOnlyTransaction) topText = 'Ponuka';
        if (isOnlyCategory) topText = 'Nehnuteľnosť';
        if (areLocationAndCategory) topText = location;
        if (areLocationAndTransaction) topText = location;
        if (areCategoryAndTransaction) topText = transaction;

        return {
            topText,
            bottomText,
            isAllSelected,
            isNothingSelected,
        };
    }, [location, category, transaction]);

    return data;
};