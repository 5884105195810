import { AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto } from 'modules/api/generated';
import { ILocationInfo } from 'modules/api/clients/advertisement/listing/interfaces/devProjects/partials/locationInfo/ILocationInfo';

export const mapLocationInfoDtoToILocationInfo = (
    dto: AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto
): ILocationInfo => ({
    city: dto.city,
    street: dto.street,
    county: dto.county,
    country: dto.country,
    district: dto.district,
    locationString: dto.locationString,
});
