import { FC } from 'react';
import { Stack, Box } from '@mui/material';
import {
    ContentItem
} from 'components/advertisementsFilter/components/common/multiInput/partials/content/partials/item/Item';
import { useMultiInputText } from 'components/advertisementsFilter/modules/common/hooks/multiInput/useMultiInputText';

interface IInputContent {
    location?: string;
    category?: string;
    transaction?: string;
}

export const InputContent: FC<IInputContent> = ({
    location,
    category,
    transaction,
}) => {
    const data = useMultiInputText(
        location,
        category,
        transaction,
    );

    return <Stack direction='column' overflow='hidden'>
        <ContentItem variant={data.isNothingSelected ? 'label2' : 'body2'} text={data.topText} semibold noWrap/>

        <Stack overflow='hidden' direction='row' gap={0.5} alignItems='center'>
            <ContentItem
                noWrap={!data.isAllSelected}
                semibold={!data.isNothingSelected}
                isColorSecondary={data.isNothingSelected}
                text={data.isAllSelected ? transaction : data.bottomText}
                variant={data.isNothingSelected ? 'body1' : 'label1'}
            />
            {data.isAllSelected &&
                <Box
                    width={4}
                    height={4}
                    borderRadius='9px'
                    bgcolor={(theme) => theme.palette.labelTertiary.main}
                />
            }
            {data.isAllSelected &&
                <ContentItem variant='label1' text={category} semibold noWrap/>
            }
        </Stack>
    </Stack>;
};