import { FC, SVGProps, InputHTMLAttributes } from 'react';
import { Box } from '@mui/material';
import { Input } from 'modules/theme/components/fields/input/Input';

interface ICommonInput {
    value: number;
    label?: string;
    placeholder?: string;
    onChange: (value: number) => void;
    endIcon: FC<SVGProps<SVGSVGElement>>;
    inputType?: InputHTMLAttributes<string>['type'];
}

export const CommonInput: FC<ICommonInput> = ({
    value,
    label,
    endIcon,
    onChange,
    inputType,
}) => {
    const handleOnChange = (inputValue: string) => {
        if (!/^\d+$/.test(inputValue) && inputValue !== '') return;

        onChange(parseInt(inputValue) || 0);
    };

    return (
        <Box sx={(theme) => ({
            '& .MuiFormControl-root .MuiOutlinedInput-root': {
                height: 56,
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${theme.palette.labelPrimary.main}!important`,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: `2px solid ${theme.palette.labelPrimary.main}!important`,
                },
            }
        })}>
            <Input
                type={inputType}
                endIcon={endIcon}
                placeholder={label}
                onChange={handleOnChange}
                label={value ? label : null}
                value={value ? value.toString() : null}
            />
        </Box>
    );
};
