import { useEffect } from 'react';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const useFulltext = (
    fulltextFromFilter: string,
): void => {
    const setFulltext = useExtendedStore((store) => store.setFulltext);

    useEffect(() => {
        if (!fulltextFromFilter) return;

        setFulltext(fulltextFromFilter);
    }, [fulltextFromFilter, setFulltext]);
};