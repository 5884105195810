import { AdvertisementsListResultAdvertisementFlagsDto } from 'modules/api/generated';
import { IAdvertisementsListResultAdvertisementFlags } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/results/partials/advertisement/partials/flags/IAdvertisementsListResultAdvertisementFlags';

export const mapFlagsDtoToIFlags = (
    dto: AdvertisementsListResultAdvertisementFlagsDto
): IAdvertisementsListResultAdvertisementFlags => ({
    isTop: dto.isTop,
    isPremium: dto.isPremium,
    hasVideo: dto.hasVideo,
    hasFloorPlan: dto.hasFloorPlan,
    hasInspections3d: dto.hasInspections3d,
});
