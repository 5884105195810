import { useEffect } from 'react';
import {
    usePrices
} from 'components/advertisementsFilter/modules/extended/hooks/prices/usePrices';
import {
    useArea
} from 'components/advertisementsFilter/modules/extended/hooks/area/useArea';
import {
    useAdvertisements
} from 'components/advertisementsFilter/modules/extended/hooks/advertisements/useAdvertisements';
import {
    useFulltext
} from 'components/advertisementsFilter/modules/extended/hooks/fulltext/useFulltext';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

export const useSearchFilterExtended = (
    filterDataFromUrl?: FilterDataFromUrlType,
): void => {
    const area = useExtendedStore((store) => store.area);
    const prices = useExtendedStore((store) => store.prices);
    const fulltext = useExtendedStore((store) => store.fulltext);
    const setIsActive = useExtendedStore((store) => store.setIsActive);
    const isOnlyWithVideo = useExtendedStore((store) => store.isOnlyWithVideo);
    const isOnlyNewBuilding = useExtendedStore((store) => store.isOnlyNewBuilding);

    usePrices({
        from: filterDataFromUrl?.priceFrom,
        to:filterDataFromUrl?.priceTo,
    });

    useArea({
        from: filterDataFromUrl?.areaFrom,
        to: filterDataFromUrl?.areaTo
    });

    useFulltext(filterDataFromUrl?.fulltext);

    useAdvertisements(
        filterDataFromUrl?.isOnlyWithVideo,
        filterDataFromUrl?.isOnlyNewBuilding,
    );

    useEffect(() => {
        const isActive =
            !!prices.from ||
            !!prices.to ||
            !!area.from ||
            !!area.to ||
            !!fulltext ||
            isOnlyNewBuilding ||
            isOnlyWithVideo;

        setIsActive(isActive);
    }, [
        setIsActive,
        isOnlyNewBuilding,
        isOnlyWithVideo,
        prices.from,
        prices.to,
        area.from,
        fulltext,
        area.to,
    ]);
};