import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import FilterIcon from 'modules/theme/components/icons/basic/Filter.svg';

interface IExtendedFilterButton {
    isActive: boolean;
    isOpened: boolean;
    onClick?: () => void;
}

const activeBadgeStyle = {
    position: 'absolute',
    width: 8,
    height: 8,
    background: 'linear-gradient(91.16deg, #E40046 -0.01%, #CE0F69 100.02%)',
    borderRadius: '50%',
};

export const ExtendedFilterButton: FC<IExtendedFilterButton> = ({
    onClick,
    isActive,
    isOpened,
}) => {

    return <Box
        gap={0.5}
        display='flex'
        padding='13px 13px 13px 10px'
        onClick={onClick}
        borderRadius='999px'
        alignItems='center'
        position='relative'
        sx={(theme) => ({
            cursor: 'pointer',
            boxShadow: isOpened && '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
            '&:hover': {
                padding: '13px 12px 13px 9px',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
                border: `1px solid ${theme.palette.dividerTransparent}`

            }
        })}
    >
        <Box
            display='flex'
            position='relative'
            alignItems='center'
        >
            {isActive && (
                <Box
                    sx={{
                        ...activeBadgeStyle,
                        top: 1,
                        right: 0,
                    }}
                />
            )}
            <Svg
                icon
                width={16}
                component={FilterIcon}
            />
        </Box>
        <Box>
            <Text
                semibold
                variant='label1'
            >
                Filter
            </Text>
        </Box>
    </Box>;
};