import { create } from 'zustand';
import { EOrder } from 'modules/api/clients/advertisement/listing/enums/EOrder';

type ISearchStore = {
    redirectUrl: URL;
    currentOrder: EOrder;
    setRedirectUrl: (url: URL) => void;
    setCurrentOrder: (order: EOrder) => void;
};

export const useSearchStore = create<ISearchStore>((set) => ({
    currentFilter: null,
    redirectUrl: null,
    currentOrder: EOrder.DEFAULT,
    setRedirectUrl: (url) => set(() => ({ redirectUrl: url })),
    setCurrentOrder: (order) => set(() => ({ currentOrder: order })),
}));