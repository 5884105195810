import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
    getListingRedirectUrl
} from 'components/advertisementsFilter/features/search/api/redirectUrl/getListingRedirectUrl';
import { useSearchStore } from 'components/advertisementsFilter/features/search/store/useSearchStore';
import { useLocationStore } from 'components/advertisementsFilter/modules/location/store/useLocationStore';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';
import { useCategoriesStore } from 'components/advertisementsFilter/modules/categories/store/useCategoriesStore';
import { useTransactionStore } from 'components/advertisementsFilter/modules/transaction/store/useTransactionStore';

interface IReturn {
    redirectUrl: URL;
    isFetching: boolean;
}

export const useAdvertisementsRedirectUrl = (): IReturn  => {
    const [isFetching, setIsFetching] = useState(false);

    const redirectUrl = useSearchStore((store) => store.redirectUrl);
    const currentOrder = useSearchStore((store) => store.currentOrder);
    const setRedirectUrl = useSearchStore((store) => store.setRedirectUrl);

    const area = useExtendedStore((store) => store.area);
    const prices = useExtendedStore((store) => store.prices);
    const fulltext = useExtendedStore((store) => store.fulltext);
    const isOnlyWithVideo = useExtendedStore((store) => store.isOnlyWithVideo);
    const selectedLocations = useLocationStore((store) => store.selectedLocations);
    const isOnlyNewBuilding = useExtendedStore((store) => store.isOnlyNewBuilding);
    const selectedCategories = useCategoriesStore((store) => store.selectedCategories);
    const selectedTransaction = useTransactionStore((store) => store.selectedTransaction);

    const getAdvertisementsRedirectUrl = useCallback(async () => {
        try {
            setIsFetching(true);
            const response = await getListingRedirectUrl({
                categories: selectedCategories.length
                    ? selectedCategories.map((selectedCategory) => selectedCategory.name)
                    : undefined,
                locations: selectedLocations.length
                    ? selectedLocations.map((selectedLocation) => selectedLocation.id)
                    : undefined,
                transaction: selectedTransaction && selectedTransaction.name,
                onlyNewbuilding: isOnlyNewBuilding,
                onlyWithVideo: isOnlyWithVideo,
                priceFrom: prices.from,
                areaFrom: area.from,
                order: currentOrder,
                priceTo: prices.to,
                areaTo: area.to,
                fulltext,
            });

            setRedirectUrl(new URL(response.url));
        } catch (error) {
            setRedirectUrl(null);
        } finally {
            setIsFetching(false);
        }
    }, [
        area.to,
        fulltext,
        area.from,
        prices.to,
        prices.from,
        currentOrder,
        setRedirectUrl,
        isOnlyWithVideo,
        selectedLocations,
        isOnlyNewBuilding,
        selectedCategories,
        selectedTransaction,
    ]);

    const debouncedRedirectUrlFetch = useDebouncedCallback(getAdvertisementsRedirectUrl, 250);

    useEffect(() => {
        (async () => {
            await debouncedRedirectUrlFetch();
        })();
    }, [debouncedRedirectUrlFetch, getAdvertisementsRedirectUrl]);

    return {
        isFetching,
        redirectUrl,
    };
};