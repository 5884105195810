import { AxiosResponse } from 'axios';
import { locationApi } from 'modules/api/client';
import { LocationResponseDto } from 'modules/api/generated';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { mapLocationResponseDtoToILocation } from 'modules/api/clients/location/helpers/mappers/location/mapLocationResponseDtoToILocation';

export class Location {
    public async getSuggestions(
        query: string,
        limit: number
    ): Promise<ILocation[]> {
        let response: AxiosResponse<LocationResponseDto[]>;

        try {
            response = await locationApi.getLocationSuggestions(query, limit);
        } catch (error) {
            throw new Error('Suggestions were not found');
        }

        if (response.status !== 200) {
            throw new Error('Suggestions were not found');
        }

        return response.data.map(mapLocationResponseDtoToILocation);
    }

    public async getLocationsByIds(
        locationIds: string[],
    ): Promise<ILocation[]> {
        let response: AxiosResponse<LocationResponseDto[]>;

        try {
            response = await locationApi.getLocationsByIds({
                locationIds,
            });
        } catch (error) {
            throw new Error('Locations by ids were not found');
        }

        if (response.status !== 201) {
            throw new Error('Locations by ids were not found');
        }

        return response.data.map(mapLocationResponseDtoToILocation);
    }
}
