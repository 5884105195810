export enum EAction {
   ADVERTISING = 'inzercia',
   AGENTS = 'makleri',
   NEWBUILDINGS = 'novostavby',
   BUTTON = 'button',
   HP = 'hp',
   MY_ACCOUNT = 'moje-konto',
   LIVE_IN_NEW_BUILDING = 'byvajte-v-novostavbe',
   WHAT_HOUSING_YOU_LOOKING_FOR = 'ake-byvanie-hladate',
   POPULAR_REGIONS_SK = 'popularne-regiony-na-slovensku',
}