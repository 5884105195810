import { FC } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import FilterIcon from 'modules/theme/components/icons/basic/Filter.svg';
import { Button } from 'modules/theme/components/button/Button';
import { useExtendedStore } from 'components/advertisementsFilter/modules/extended/store/useExtendedStore';

interface IExtendedFilterButton {
    onClick?: () => void;
}

const activeBadgeStyle = {
    position: 'absolute',
    width: 10,
    height: 10,
    background: 'linear-gradient(91.16deg, #E40046 -0.01%, #CE0F69 100.02%)',
    borderRadius: '50%',
};

export const ExtendedFilterButton: FC<IExtendedFilterButton> = ({
    onClick,
}) => {
    const isActive = useExtendedStore((store) => store.isActive);

    return <Box sx={{
        zIndex: 10,
        '& .MuiButton-quaternary': {
            minWidth: 0,
            borderRadius: '99px',
            padding: `${8}px!important`
        }
    }}>
        <Button
            small
            quaternary
            onClick={onClick}
            ariaLabel={'search-button'}
            text={(
                <Box
                    gap={1}
                    display='flex'
                    alignItems='center'
                    position='relative'
                >
                    {isActive && (
                        <Box
                            sx={{
                                ...activeBadgeStyle,
                                top: 2,
                                right: 2,
                            }}
                        />
                    )}
                    <Svg
                        icon
                        width={24}
                        component={FilterIcon}
                    />
                </Box>
            )}
        />
    </Box>;
};