import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IContentItemWrapper {
    children: ReactNode;
}

export const ContentItemWrapper: FC<IContentItemWrapper> = ({ children }) => {
    return <Box
        width='100%'
        display='flex'
        flexDirection='column'
        pb={{ xs: 3 }}
        sx={(theme) => ({
            '& .MuiChip-root': {
                '&:hover': {
                    cursor: { md: 'pointer'},
                    color: { md: theme.palette.backgroundPrimary.main },
                    borderColor: { md: theme.palette.labelPrimary.main },
                    backgroundColor: { md: theme.palette.labelPrimary.main},
                },
            }
        })}
    >
        {children}
    </Box>;
};